import DatePicker from "react-datepicker";
import {useField} from "formik";


const FormikDateInput = ({...props}) => {
    const [field, meta, helpers] = useField(props.name);

    const {value} = meta;
    const {setValue} = helpers;

    const handleSetValue = (date: Date | null) => {
        setValue(date);

        if ("sendDate" in props) {
            props.sendDate(date, props.name);
        }
    }

    return (
        <DatePicker
            {...field}
            {...props}
            selected={value}
            onChange={(date) => handleSetValue(date)}
            // isClearable
            dateFormat={props.dateFormat || 'yyy-MM-dd'}
            className={props.class}
        />
    );
};

export default FormikDateInput;
