import React, {FC, useState} from "react";
import {P1Event} from "../../../models/Event";
import {p1EventsPatch} from "../../../@api/Event";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import EditEventForm from "../EditP1EventForm/EditEventForm";
import {FormikValues} from "formik";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";

interface P1EventsTableProps {
    pagination: {
        paginate: (params?: any) => Promise<void>,
        items: P1Event[],
        setItems: React.Dispatch<React.SetStateAction<P1Event[]>>,
        currentPage: number,
        setCurrentPage: (v: number) => void,
        totalItems: number,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>,
        lastPage: number,
        perPage: number,
        setPerPage: (v: number) => void,
        isLoading: boolean,
        error: string
    };
    selectedPage: number;
    filterValues: FormikValues;
}

const P1EventsTable: FC<P1EventsTableProps> = ({pagination, selectedPage, filterValues}) => {
    const [showEdit, setShowEdit] = useState<number | null>(null);
    const [showImage, setShowImage] = useState<number | null>(null);

    const edit = (id: number) => {
        setShowEdit(id);
    };

    const handleEditClose = () => {
        pagination.setCurrentPage(selectedPage);
        pagination.setPerPage(pagination.perPage);
        pagination.paginate(filterValues);
        setShowEdit(null);
    };

    const handleToMap = (event_id: number, checked: boolean) => {
        p1EventsPatch(event_id, {'to_map': checked}).then(res => {
            if (res instanceof HttpFailureResponse) {
                alert(res?.message);
            }
        });
    };

    const tableHeaders = [
        'ID',
        'Name',
        'SB Name',
        'Venue',
        'City',
        'Series',
        'Category',
        'Date',
        'To map',
        ''
    ];

    const handleImageClose = () => {
        setShowImage(null);
    };

    return (
        <AppTable
            columns={tableHeaders}
            pagination={{
                lastPage: pagination.lastPage,
                currentPage: pagination.currentPage,
                total_items: pagination.totalItems
            }}
            onPageChange={(page, perPage) => {
                pagination.setCurrentPage(page);
                pagination.setPerPage(perPage);
                pagination.paginate(filterValues);
            }}
            withAutoHeight={false}
        >
            <>
                {pagination.items.length
                    ? pagination.items?.map((event, index) =>
                        <React.Fragment key={'P1Events' + index}>
                            <tr data-row={index}>
                                <td>
                                    <div className={"app-paginated-table_td-id"}>
                                        {event.p1_id}
                                        <span className={"app-paginated-table_td-id__tooltip"}>{event.p1_id}</span>
                                    </div>
                                </td>
                                <td>{event.name}</td>
                                <td>{event.sb_event?.name}</td>
                                <td>{event.venue}</td>
                                <td>{event.city}</td>
                                <td>{event.p1_series?.name}</td>
                                <td>{event.p1_category.name}</td>
                                <td>{dateToLocaleString(event.date_start)}</td>
                                <td>
                                    <input key={'map' + event.id} name={'to_map'} type='checkbox'
                                           defaultChecked={event.to_map}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleToMap(event.id, e.target.checked)}/>
                                </td>
                                <td>
                                    <div className="customers_actions">
                                        <div onClick={() => edit(event.id)}>
                                            <AppButton text={'Map'}/>
                                        </div>
                                        {event.image ?
                                            <div onClick={() => setShowImage(event.id)}>
                                                <AppButton text={'Image'}/>
                                            </div>
                                            : <></>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <AppModal key={'editModal' + event.id} show={showEdit === event.id}
                                      showHandle={() => setShowEdit(null)} title={'Select Event'} form={
                                <EditEventForm
                                    showHandle={handleEditClose}
                                    event_id={event.id}
                                    sb_event={event.sb_event}
                                    name={event.name}
                                    venue={event.venue}
                                    city={event.city}
                                    date={event.date_start}/>
                            } onClose={() => {
                            }}/>
                            <AppModal key={'imageModal' + event.id} show={showImage === event.id}
                                      showHandle={handleImageClose} title={'Venue Image'} form={
                                <img src={event.image} alt={event.venue}/>
                            } onClose={() => {
                            }}/>
                        </React.Fragment>
                    )
                    :
                    <tr className={"events-page__table__tr "}>
                        <td className={"text-center"} colSpan={8}>No events</td>
                    </tr>
                }
            </>
        </AppTable>
    )
}

export default P1EventsTable;
