import React, {FC, useEffect, useState} from 'react';
import {Accordion, Tab, Tabs} from 'react-bootstrap';
import './SellPage.scss';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {Form, Formik} from "formik";
import DefaultFormikInput from "../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import FormikDateInput from "../../../components/Inputs/FormikDateInput/FormikDateInput";
import {clearFilter, setFilter} from "../../../store/Reducers/Filters";
import ImportFileForm from "../../../components/SellPage/ImportFileForm/ImportFileForm";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import MatchCard from "./components/MatchCard/MatchCard";
import TableEventBlock from "./components/TableEventBlock/TableEventBlock";
import MobileFilter from "./components/MobileFilter/MobileFilter";
import useWindowWidth from "../../../hooks/useHandleWindowResize";
import {Performer} from "../../../models/Performer";
import {SbEvent} from "../../../models/Event";
import {Inventory} from "../../../models/Inventory";
import {MOBILE_WIDTH} from "../../../constants/Layout";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {performersNotPaginated} from "../../../@api/Performer";
import {getInventoriesByEvent} from "../../../@api/Inventory";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";
import { userHasIntegrations } from '../../../helpers/InventoryHelper';

interface TableData {
    event: SbEvent,
    inventories: Inventory[]
}

interface SellData {
    event: SbEvent,
    inventories: Inventory[]
}

const SellPage: FC = () => {
    const windowWidth = useWindowWidth();
    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const [sellData, setSellData] = useState<SellData[]>();
    const [selectedMatches, setSelectedMatches] = useState<any>('');
    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
    const [sortOptions, setSortOptions] = useState<string>(' ');
    const [eventData, setEventData] = useState([]);
    const [performerData, setPerformerData] = useState<Performer[]>([]);
    const [searchMatches, setSearchMatches] = useState<string>('');
    const [toggleMatches, setToggleMatches] = useState<number[]>([]);
    const [performerMatches, setPerformerMatches] = useState<number[]>([]);
    const [refreshInterval, setRefreshInterval] = useState(0);
    const [showAddFromFile, setShowAddFromFile] = useState<number | null>(null);
    const [accordionOpen, setAccordionOpen] = useState(true);

    const hasIntegrations = userHasIntegrations(loggedUser);

    const dispatch = useDispatch();

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const calculateMaxHeight = () => {
        return accordionOpen ? '300px' : '600px';
    };

    const getData = () => {
        getInventoriesByEvent(selectedMatches.id)
            .then((response) => {
                if (response instanceof HttpSuccessResponse) {
                    setSellData(response.data.data);
                    setEventData(response.data.events);
                    setPerformerData(response.data.performers ?? [])
                }
            });
    };

    useEffect(() => {
        getData();

        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(getData, refreshInterval);
            return () => clearInterval(interval);
        }
        if (windowWidth <= 1250) {
            setIsOpenSidebar(false)
        } else {
            setIsOpenSidebar(true)
        }
    }, [refreshInterval, windowWidth])

    const selectEventFromMatches = (eventData: any) => {
        let addNew = toggleMatches.filter((el: any) => el === eventData.id);
        if (!addNew.length) {
            setToggleMatches([...toggleMatches, eventData.id])
        } else {
            setToggleMatches([...(toggleMatches.filter((el: any) => el !== eventData.id))])
        }

    }

    const selectPerformerFromMatches = (performerData: Performer) => {
        const addNew = performerMatches.filter((el: any) => el === performerData.id);

        if (!addNew.length) {
            setPerformerMatches([...performerMatches, performerData.id])
        } else {
            setPerformerMatches([...(performerMatches.filter((el: any) => el !== performerData.id))])
        }
    }

    function dynamicSortOptions(property: string) {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        if (property === '') return;
        return function (a: any, b: any) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const filterInitial = {section: '', from_date: undefined, to_date: undefined, row: '', name: '', inventory_id: ''};

    const resetFilterForm = () => {
        dispatch(clearFilter());
    }

    const handleAddFromFileClose = () => {
        setShowAddFromFile(null);
    }

    const addFromFile = () => {
        setShowAddFromFile(1);
    }

    const filterInputs = [
        {
            name: 'name',
            placeholder: 'Search tickets by name',
            autoComplete: 'off',
            class: 'filters-search'
        },
        {
            name: 'section',
            placeholder: 'Search tickets by section',
            autoComplete: 'off',
            class: 'filters-search'
        },
        {
            name: 'row',
            placeholder: 'Search tickets by row',
            autoComplete: 'off',
            class: 'filters-search'
        },
        {
            name: 'inventory_id',
            placeholder: 'Search tickets by Inventory ID',
            autoComplete: 'off',
            class: 'filters-search'
        },
    ]

    const formDateInputs = [
        {
            name: 'from_date',
            placeholderText: 'From date',
            className: '',
        },
        {
            name: 'to_date',
            placeholderText: 'To date',
            className: '',
        },
    ]

    const performersSort = (a: Performer, b: Performer) => {
        return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    }

    return (
        <>
            {isOpenSidebar && windowWidth <= 1024 ? (
                <div className="sidebar-overlay"></div>
            ) : null}
            <div className="filters-sidebar">
                <div className="filters-sidebar-toggle" onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
                    {isOpenSidebar ? <span>&#171;</span> : <span>&#187;</span>}
                </div>
                <div className={isOpenSidebar ? 'filters filters-expand' : 'filters-collapse'}>
                    {
                        isOpenSidebar
                            ? <>
                                <Accordion defaultActiveKey="0" onSelect={toggleAccordion}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="filters-accordion-header">
                                            Filters
                                        </Accordion.Header>
                                        <hr/>
                                        <Accordion.Body className="filters-accordion-body">
                                            <Formik
                                                initialValues={filterInitial}
                                                onSubmit={async (values) => {
                                                    dispatch(setFilter(values));
                                                }}
                                            >
                                                {({isSubmitting}) =>
                                                    <Form className="filters-form">
                                                        {
                                                            filterInputs.map((formInput, index) => (
                                                                <DefaultFormikInput
                                                                    key={`filters-form-input-${index}`}
                                                                    name={formInput.name}
                                                                    placeholder={formInput.placeholder}
                                                                    autocomplete={formInput.autoComplete}
                                                                    class={formInput.class}
                                                                ></DefaultFormikInput>
                                                            ))
                                                        }
                                                        <div className="events-page__filters__component">
                                                            <AppSmartSearch
                                                                name={'performer'}
                                                                placeholder={'Search by performer'}
                                                                requestFunction={performersNotPaginated}
                                                                valuesKey={'name'}
                                                            />
                                                        </div>
                                                        <div className="filters-date">
                                                            {formDateInputs.map((formDateInput, index) => (
                                                                <div key={`filters-date-input-${index}`}>
                                                                    <FormikDateInput
                                                                        name={formDateInput.name}
                                                                        placeholderText={formDateInput.placeholderText}
                                                                        className={formDateInput.className}
                                                                        autoComplete={'off'}
                                                                    />
                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                        <div className="filters-button">
                                                            <button type='reset' onClick={() => resetFilterForm()}
                                                                    className="filters-button-reset">
                                                                Reset
                                                            </button>
                                                            <button type="submit" className="filters-button-apply">
                                                                Apply Filters
                                                            </button>
                                                        </div>
                                                    </Form>
                                                }
                                            </Formik>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="matches">
                                    <input
                                        onChange={e => setSearchMatches(e.target.value)}
                                        value={searchMatches}
                                        className="matches-body-search matches-body-search-default"
                                        type="text"
                                        placeholder="Name match"
                                    />
                                    <Tabs defaultActiveKey={'events'}>
                                        <Tab title={`Events (${eventData?.length})`} eventKey={'events'}>
                                            <select
                                                onChange={(e) => setSortOptions(e.target.value)}
                                                defaultValue={'occurs_at'}
                                                className="matches-sort"
                                            >
                                                <option value="occurs_at">Sort by date &darr;</option>
                                                <option value="-occurs_at">Sort by date &uarr;</option>
                                                <option value="name">Sort by name &darr;</option>
                                                <option value="-name">Sort by name &uarr;</option>
                                            </select>
                                            <div className="matches-list" style={{maxHeight: calculateMaxHeight()}}>
                                                {
                                                    eventData?.length
                                                        ? eventData
                                                            .sort(dynamicSortOptions(sortOptions))
                                                            .filter((data: SbEvent) => data.name.includes(searchMatches))
                                                            .map((data: SbEvent) =>
                                                                <MatchCard key={`matches-list-card-${data.id}`} event={data}
                                                                           clickFunc={() => selectEventFromMatches(data)}/>
                                                            )
                                                        : <></>
                                                }
                                            </div>
                                        </Tab>
                                        <Tab title={`Performers (${Object.entries(performerData)?.length})`}
                                             eventKey={'performers'}>
                                            <div className="matches-list">
                                                {
                                                    Object.entries(performerData)
                                                        .sort(([, a], [, b]) => performersSort(a, b))
                                                        .filter(([, performer]) => performer.name.includes(searchMatches))
                                                        .map(([id, performer]) =>
                                                            <MatchCard key={`matches-list-card-performers-${id}`} performer={performer}
                                                                       clickFunc={() => selectPerformerFromMatches(performer)}/>
                                                        )
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </>
                            : <></>
                    }
                </div>
            </div>
            <div className={'user-sell-page'}>
                <div className="user-sell-page__top-block">
                    <div className="user-sell-page__top-block-title">
                        <p>Choose tickets</p>
                    </div>
                    <div className="user-sell-page__table-block-upload-file">
                        <Link to={'./sell-ticket'}>Add inventory</Link>
                        <span onClick={() => addFromFile()}>Add multiple inventory</span>
                    </div>
                </div>
                <div className="user-sell-page__table-block">
                    {windowWidth <= MOBILE_WIDTH ? <MobileFilter/> : null}
                    <div className="user-sell-page__header">
                        <h4 className="user-sell-page__header-title">
                            All your uploaded tickets
                        </h4>
                    </div>
                    {sellData ?
                        <div className="user-sell-page__table-wrapper">
                            <table className="user-sell-page__table">
                                <thead className="user-sell-page__table-head-text sticky-header">
                                <tr>
                                    <th></th>
                                    <th>Qty</th>
                                    <th>Shown qty</th>
                                    <th>Inv ID</th>
                                    <th>Section</th>
                                    <th>Row</th>
                                    <th>Low seat</th>
                                    <th>Unit price</th>
                                    <th>Status</th>
                                    <th>Internal Notes</th>
                                    <th>Public Notes</th>
                                    <th>Broadcast</th>
                                    <th>Seat type</th>
                                    {hasIntegrations && <th>Integration statuses</th>}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sellData?.length
                                        ? sellData
                                            .filter((data: TableData) => toggleMatches?.length ? toggleMatches.includes(data.event.id) : true)
                                            .filter((data: TableData) => performerMatches?.length ? performerMatches.includes(data.event.sb_performer_id) : true)
                                            .map((data: TableData) =>
                                                <TableEventBlock key={`sell-page-table-row-${data.event.event_id}`} event={data.event}
                                                                 inventories={data.inventories} getData={getData}
                                                                 selectedCurrency={''}
                                                                 actions={'change'}/>
                                            )
                                        : <tr>
                                            <td colSpan={loggedUser!.api_keys?.ticombo ? 14 : 13}>No inventory available
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className={'mt-5'}><AppLoader/></div>
                    }
                </div>
            </div>
            <AppModal key={'addFromFile1'} show={showAddFromFile === 1}
                      showHandle={handleAddFromFileClose} title={'Show From File'} form={
                <ImportFileForm showHandle={handleAddFromFileClose}/>
            } onClose={() => {
            }}/>
        </>
    );
};

export default SellPage;
