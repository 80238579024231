import {PaginationParams} from "../@interfaces/PaginationParams";
import {Event, LTGEvent, P1Event, SbEvent} from "../models/Event";
import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {PaginationResponseData} from "../@interfaces/Response/PaginationResponseData";
import axios from "axios";
import {FormikValues} from "formik";
import {AuthResponseStatusesEnum} from "../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {RegistrationSuccessResponse} from "../@interfaces/Response/Auth";
import {EventsFilters} from "../@interfaces/EventsFilters";
import {TicomboEvent} from "../models/TicomboEvent";
import {StubhubEvent} from "../models/StubhubEvent";
import {AcMilanEvent} from "../models/AcMilanEvent";
import {RequestNewEvent} from "../@interfaces/RequestNewEvent";

export const eventsPaginateRequest = (params: PaginationParams<Event>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<Event>>(axios.get('/events', {
        params: params,
    }));
};

export const deleteEvent = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.delete('/events/' + id),
    );
};

export const sbEventsPaginateRequest = (params: PaginationParams<SbEvent>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<SbEvent>>(axios.get('/sb_events', {
        params: params,
    }));
};

export const sbEventsLimitRequest = (event: FormikValues) => {
    event.limit = 50;
    return axios.get('/sb_events', {params: {...event}})
};

export const p1EventsPaginateRequest = (params: PaginationParams<P1Event>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<P1Event>>(axios.get('/p1_events', {
        params: params,
    }));
};

export const acMilanEventsPaginateRequest = (params: PaginationParams<AcMilanEvent>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<AcMilanEvent>>(axios.get('/acMilanEvents', {
        params: params,
    }));
};

export const ltgEventsPaginateRequest = (params: PaginationParams<LTGEvent>) => {
    params.perPage = params.perPage ? params.perPage : 50;
    return requestWrapper<GlobalStatusCodesEnum, PaginationResponseData<LTGEvent>>(axios.get('/integrations/ltg/events', {
        params: params,
    }));
};

export const p1EventsPatch = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.patch('/p1_events/' + id, params),
    );
};

export const acMilanEventsPatch = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.patch('/acMilanEvents/' + id, params),
    );
};

export const sbEventsSetToMap = (id: number, params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum, string>(
        axios.patch('/sb_events/' + id, params),
    );
};

export const getEventById = (id: number) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.get('/sb_events/' + id),
    );
};

export const bindSbIdWithLTG = (params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post('/integrations/ltg/events/bind', params),
    );
};

export const unbindSbIdWithLTG = (params: FormikValues) => {
    return requestWrapper<GlobalStatusCodesEnum & AuthResponseStatusesEnum, RegistrationSuccessResponse>(
        axios.post('/integrations/ltg/events/unbind', params),
    );
};

export const getEventsToMap = (params: EventsFilters) => {
    return requestWrapper<GlobalStatusCodesEnum, { ltgEvents: [], tcEvents: [], shEvents: [] }>(
        axios.get('/sb_events/toMap', {params}),
    );
};

export const getMappedEvents = (sbEventId: number) => {
    return requestWrapper<GlobalStatusCodesEnum, { ltgEvents: [], tcEvents: [], shEvents: [] }>(
        axios.get('/sb_events/mappedTo/' + sbEventId),
    );
};

export const patchMappedEvents = (sbEventId: number, events: {
    ltgEvents?: LTGEvent[],
    tcEvents?: TicomboEvent[],
    shEvents?: StubhubEvent[]
}) => {
    return requestWrapper<GlobalStatusCodesEnum, { ltgEvents: boolean, tcEvents: boolean, shEvents: boolean }>(
        axios.patch('/sb_events/map/' + sbEventId, {events})
    );
};

export const requestNewEvent = (data: RequestNewEvent) => {
    return requestWrapper<GlobalStatusCodesEnum, RequestNewEvent>(
        axios.post('events/requestNewEvent', data)
    )
}
