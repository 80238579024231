import {FC, useEffect, useState} from "react";
import {Field} from "formik";
import {getSeries} from "../../../@api/Series";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {P1Category} from "../../../models/Category";
import {get} from "../../../@api/Categories";

const P1EventCategoryFilter: FC = () => {

    const [series, setSeries] = useState<string[]>([]);
    const [categories, setCategories] = useState<P1Category[]>([]);

    useEffect(() => {
        getSeries().then((data) => {
            if (data instanceof HttpSuccessResponse) {
                setSeries(data.data);
            }
        });
        get().then((res) => setCategories(res.data));
    }, []);

    return (
        <>
            <div className="events-page__filters__component">
                <Field name={'series'} as="select"
                       style={{display: 'block', backgroundColor: 'white'}}>

                    <option key={'emptyP1EventSeries'} value="">All Series</option>
                    {
                        series.map(categoryOption => (
                            <option key={categoryOption + 'P1EventSeries'}
                                    value={categoryOption}>{categoryOption}</option>
                        ))
                    }
                </Field>
            </div>
            <div className="events-page__filters__component">
                <Field name={'category'} as="select"
                       style={{display: 'block', backgroundColor: 'white'}}>

                    <option key={'emptyP1EventCategory'} value="">All Categories</option>
                    {
                        categories.map(categoryOption => (
                            <option key={categoryOption.id + 'P1EventCategory'}
                                    value={categoryOption.name}>{categoryOption.name}</option>
                        ))
                    }
                </Field>
            </div>
            <div className="events-page__filters__component">
                <Field name={'operator'} as="select"
                       style={{display: 'block', backgroundColor: 'white'}}>

                    <option value="">All Events</option>
                    <option value="=">Unmapped</option>
                    <option value="<>">Mapped</option>
                </Field>
            </div>
        </>
    );
}

export default P1EventCategoryFilter;
