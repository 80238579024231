import React, {FC, useEffect, useState} from "react";
import {Container, Tab, Tabs} from "react-bootstrap";
import EventsComponent from "../../../components/EventsPage/EventsComponent/EventsComponent";
import usePagination from "../../../helpers/UsePagination";
import {Field} from "formik";
import SbEventReportsTable from "./components/SbEventReportsTable/SbEventReportsTable";
import {MappedEntitiesEnum} from "../../../enums/MappedEntitesEnum";
import {amountMapReportsByAdmins, reportsPaginateRequest} from "../../../@api/Report";
import {User} from "../../../models/User";
import {usersPaginateRequest} from "../../../@api/User";
import {RolesEnum} from "../../../enums/RolesEnum";
import SbInventoryReportsTable from "./components/SbInventoryReportsTable/SbInventoryReportsTable";
import {AmountMapReport} from "../../../models/AmountMapReport";
import AmountReportsTable from "./components/AmountReportsTable/AmountReportsTable";
import {Log} from "../../../models/Log";
import {heightTableStyle} from "../../../helpers/SpecificStyles";

const ReportsPage: FC = () => {
    const [sbEventPage, setSbEventPage] = useState<number>(1);
    const [sbInventoryPage, setSbInventoryPage] = useState<number>(1);
    const [amountReportsPage, setAmountReportsPage] = useState<number>(1);

    const admins = usePagination<User>(usersPaginateRequest);

    useEffect(() => {
        getAdmins();
        changeDateFiltersPlaceholders();
    }, [])

    useEffect(() => {
        const elements = document.getElementsByClassName('app-paginated-table-wrapper') as unknown as HTMLElement[];

        for (const element of elements) {
            element.style.height = heightTableStyle('app-paginated-table-wrapper', 200).height;
        }
    }, []);

    const changeDateFiltersPlaceholders = () => {
        for (let i = 0; i < 3; i++) {
            const fromDate = document.getElementsByName('from_date')[i] as HTMLInputElement;
            const toDate = document.getElementsByName('to_date')[i] as HTMLInputElement;

            fromDate.placeholder = 'From date of mapping'
            toDate.placeholder = 'To date of mapping'
        }
    }

    const getAdmins = async () => {
        await admins.paginate({
            role_id: RolesEnum.SuperAdmin,
            get_admins: true
        });
    }

    const sbEventFilterInitial = {
        id: '',
        sb_event_name: '',
        admin_id: '',
        mapped:
            MappedEntitiesEnum.mapLtgEvent + ',' + MappedEntitiesEnum.unmapLtgEvent + ',' +
            MappedEntitiesEnum.mapTicomboEvent + ',' + MappedEntitiesEnum.unmapTicomboEvent + ',' +
            MappedEntitiesEnum.mapStubhubEvent + ',' + MappedEntitiesEnum.unmapStubhubEvent,
        from_date: null,
        to_date: null,
    };

    const sbInventoryFilterInitial = {
        id: '',
        sb_inventory_name: '',
        admin_id: '',
        mapped:
            MappedEntitiesEnum.mapLtgInventory + ',' +
            MappedEntitiesEnum.mapTicomboInventory + ',' +
            MappedEntitiesEnum.mapStubhubSection,
        from_date: null,
        to_date: null,
    };

    const amountReportsFilterInitial = {
        from_date: null,
        to_date: null,
    };

    const [sbEventFilterValues, setSbEventFilterValues] = useState(sbEventFilterInitial);
    const [sbInventoryFilterValues, setSbInventoryFilterValues] = useState(sbInventoryFilterInitial);
    const [amountReportsFilterValues, setAmountReportsFilterValues] = useState(amountReportsFilterInitial);

    const paginationSbEvent = usePagination<Log>(reportsPaginateRequest);
    const paginationSbInventory = usePagination<Log>(reportsPaginateRequest);
    const paginationAmountReport = usePagination<AmountMapReport>(amountMapReportsByAdmins);

    const sbEventFormValues = [
        [
            {key: "id", value: "Search reports by SB ID"},
            {key: "sb_event_name", value: "Search reports by SB name"},
        ],
    ];

    const sbInventoryFormValues = [
        [
            {key: "id", value: "Search reports by SB ID"},
            {key: "sb_inventory_name", value: "Search reports SB name"},
        ],
    ];

    const mappedEventsSelectOptions = [
        {
            title: 'All services',
            value:
                MappedEntitiesEnum.mapLtgEvent + ',' + MappedEntitiesEnum.unmapLtgEvent + ',' +
                MappedEntitiesEnum.mapTicomboEvent + ',' + MappedEntitiesEnum.unmapTicomboEvent + ',' +
                MappedEntitiesEnum.mapStubhubEvent + ',' + MappedEntitiesEnum.unmapStubhubEvent
        },
        {
            title: 'Live Ticket Group',
            value: MappedEntitiesEnum.mapLtgEvent + ',' + MappedEntitiesEnum.unmapLtgEvent
        },
        {
            title: 'Ticombo',
            value: MappedEntitiesEnum.mapTicomboEvent + ',' + MappedEntitiesEnum.unmapTicomboEvent
        },
        {
            title: 'Stubhub',
            value: MappedEntitiesEnum.mapStubhubEvent + ',' + MappedEntitiesEnum.unmapStubhubEvent
        }
    ];

    const mappedInventoriesSelectOptions = [
        {
            title: 'All services',
            value: MappedEntitiesEnum.mapLtgInventory + ',' + MappedEntitiesEnum.mapTicomboInventory
        },
        {
            title: 'Live Ticket Group',
            value: MappedEntitiesEnum.mapLtgInventory
        },
        {
            title: 'Ticombo',
            value: MappedEntitiesEnum.mapTicomboInventory
        },
        {
            title: 'Stubhub',
            value: MappedEntitiesEnum.mapStubhubSection
        }
    ];

    const eventsReportCustomFilter = () => {
        return (
            <>
                <div className="events-page__filters__component">
                    <Field name={'admin_id'} as="select">
                        <option value={''}>All admins</option>
                        {
                            admins.items.map(admin => (
                                <option key={admin.email + 'P1EventCategory'}
                                        value={admin.id}>{admin.email}</option>
                            ))
                        }
                    </Field>
                </div>
                <div className="events-page__filters__component">
                    <Field name={'mapped'} as="select">
                        {
                            mappedEventsSelectOptions.map(selectOption => (
                                <option key={selectOption.title + 'P1EventCategory'}
                                        value={selectOption.value}>{selectOption.title}</option>
                            ))
                        }
                    </Field>
                </div>
            </>
        )
    };

    const inventoriesReportCustomFilter = () => {
        return (
            <>
                <div className="events-page__filters__component">
                    <Field name={'admin_id'} as="select">
                        <option value={''}>All admins</option>
                        {
                            admins.items.map(admin => (
                                <option key={admin.email + 'P1EventCategory'}
                                        value={admin.id}>{admin.email}</option>
                            ))
                        }
                    </Field>
                </div>
                <div className="events-page__filters__component">
                    <Field name={'mapped'} as="select">
                        {
                            mappedInventoriesSelectOptions.map(selectOption => (
                                <option key={selectOption.title + 'P1EventCategory'}
                                        value={selectOption.value}>{selectOption.title}</option>
                            ))
                        }
                    </Field>
                </div>
            </>
        )
    }

    return (
        <Container className={'admin-users-page events'}>
            <h1 className={'admin-users-page__title'}>Reports</h1>
            <Tabs defaultActiveKey={'EventReports'}>
                <Tab title={'Event reports'} eventKey={'EventReports'}>
                    <EventsComponent
                        pagination={paginationSbEvent}
                        filterInitial={sbEventFilterInitial}
                        formValues={sbEventFormValues}
                        filterValues={sbEventFilterValues}
                        setFilterValues={setSbEventFilterValues}
                        selectedPage={sbEventPage}
                        setSelectedPage={setSbEventPage}
                        tableComponent={
                            <SbEventReportsTable
                                pagination={paginationSbEvent}
                                filterValues={sbEventFilterValues}
                                selectedPage={sbEventPage}/>
                        }
                        customFilters={eventsReportCustomFilter()}
                    />
                </Tab>
                <Tab title={'Category reports'} eventKey={'CategoryReports'}>
                    <EventsComponent
                        pagination={paginationSbInventory}
                        filterInitial={sbInventoryFilterInitial}
                        formValues={sbInventoryFormValues}
                        filterValues={sbInventoryFilterValues}
                        setFilterValues={setSbInventoryFilterValues}
                        selectedPage={sbInventoryPage}
                        setSelectedPage={setSbInventoryPage}
                        tableComponent={
                            <SbInventoryReportsTable
                                pagination={paginationSbInventory}
                                filterValues={sbInventoryFilterValues}
                                selectedPage={sbInventoryPage}/>
                        }
                        customFilters={inventoriesReportCustomFilter()}
                    />
                </Tab>
                <Tab title={'Amount'} eventKey={'Amount'}>
                    <EventsComponent
                        pagination={paginationAmountReport}
                        filterInitial={amountReportsFilterInitial}
                        formValues={[[]]}
                        filterValues={amountReportsFilterValues}
                        setFilterValues={setAmountReportsFilterValues}
                        selectedPage={amountReportsPage}
                        setSelectedPage={setAmountReportsPage}
                        tableComponent={
                            <AmountReportsTable
                                pagination={paginationAmountReport}
                                filterValues={amountReportsFilterValues}
                                selectedPage={amountReportsPage}/>
                        }
                        customFilters={<></>}
                    />
                </Tab>
            </Tabs>
        </Container>
    );
}

export default ReportsPage;
