import { Form, Formik, FormikValues } from 'formik';
import DefaultFormikInput from '../../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput';
import React, { useState } from 'react';
import { IntegrationsEnum } from '../../../../../../enums/IntegrationsEnum';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Inventory } from '../../../../../../models/Inventory';
import { integrations } from '../../../../../../constants/Integrations';
import AppButton from '../../../../../../ui/Buttons/AppButton/AppButton';
import { setInventoryCustomPriceRequest } from '../../../../../../@api/User';
import { HttpSuccessResponse } from '../../../../../../@api/Responses/HttpSuccessResponse';
import { Message, useToaster } from 'rsuite';
import { ERROR_DURATION, SUCCESS_DURATION } from '../../../../../../constants/Constants';

interface IIntegrationFormProps {
    inventory: Inventory;
    currency: string;
    customPrice: number | null;
    integrationPrice: number;
    integrationType: IntegrationsEnum,
    onUpdatedCallback?: () => void
}

export const EventIntegration: React.FC<IIntegrationFormProps> = ({
    inventory,
    currency,
    customPrice,
    integrationPrice,
    integrationType
}) => {
    const toaster = useToaster();
    const [loading, setLoading] = useState(false);
    const onChangeValues = async (values: FormikValues) => {
        setLoading(true);

        let message;
        let type: 'error' | 'success' = 'error';
        try {
            const res = await setInventoryCustomPriceRequest(Number(inventory.id), integrationType, values.customPrice);
            const isSuccess = res instanceof HttpSuccessResponse
            type = isSuccess ? 'success' : 'error';
            message = res?.message;
            if (!message) {
                message = isSuccess ? 'Successfully updated' : 'Custom'
            }
        } catch (e: any) {
            message = e?.message;
        }

        setLoading(false);
        toaster.push(<Message showIcon type={type} closable>{message}</Message>, {
            duration: type === 'error' ? ERROR_DURATION : SUCCESS_DURATION
        });
    }

    const currencyIcon = getSymbolFromCurrency(currency);
    const inventoryPrice = String(integrationPrice);
    const label = integrations.find(i => i.key === integrationType)?.inputName;

    return (
        <Formik
            initialValues={{customPrice}}
            onSubmit={onChangeValues}>
            <Form className={'d-flex flex-nowrap flex-row align-items-center justify-content-end gap-2'}>
                <span>{label}</span>
                <DefaultFormikInput name={'customPrice'}
                                    prefix={currencyIcon}
                                    className={"my-1 price-input"}
                                    placeholder={inventoryPrice}
                                    autocomplete={'off'}
                ></DefaultFormikInput>
                <AppButton color={'red'} text={'Set'} type={'submit'} size={'sm'} isLoading={loading}></AppButton>
            </Form>
        </Formik>
    )
}
