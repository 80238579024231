import React, {FC, useState} from "react";
import {SbEvent} from "../../../models/Event";
import {FormikValues} from "formik";
import {dateToLocaleString} from "../../../helpers/DateFormatter";
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import MapForm from "../../../pages/Admin/EventsPage/components/MapForm/MapForm";
import {HttpFailureResponse} from "../../../@api/Responses/HttpFailureResponse";
import {sbEventsSetToMap} from "../../../@api/Event";
import IconYes from "../../../assets/images/other/check.svg";
import IconNo from "../../../assets/images/other/x-close.svg";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";

interface Props {
    pagination: {
        paginate: (params?: any) => Promise<void>,
        items: SbEvent[],
        setItems: React.Dispatch<React.SetStateAction<SbEvent[]>>,
        currentPage: number,
        setCurrentPage: (v: number) => void,
        totalItems: number,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>,
        lastPage: number,
        perPage: number,
        setPerPage: (v: number) => void,
        isLoading: boolean,
        error: string
    };
    selectedPage: number;
    filterValues: FormikValues;
}

const SbEventsTable: FC<Props> = ({pagination, selectedPage, filterValues}) => {
    const tableHeaders = [
        'ID',
        'Name',
        'Performer',
        'Venue',
        'City',
        'Category',
        'Date',
        'To map',
        'Mapped',
        ''
    ];

    const servicesIcons = [
        {name: 'LTG', key: 'ltg_event'},
        {name: 'TC', key: 'ticombo_event'},
        {name: 'SH', key: 'stubhub_event'}
    ];

    const [showMap, setShowMap] = useState<number | null>(null);

    const handleToMap = (event_id: number, checked: boolean) => {
        sbEventsSetToMap(event_id, {'to_map': checked}).then(res => {
            if (res instanceof HttpFailureResponse) {
                alert(res?.message);
            }
        });
    };

    const handleMapClose = async () => {
        pagination.setCurrentPage(selectedPage);
        pagination.setPerPage(pagination.perPage);
        await pagination.paginate(filterValues);
        setShowMap(null);
    };

    return (
        <AppTable
            columns={tableHeaders}
            pagination={{
                lastPage: pagination.lastPage,
                currentPage: pagination.currentPage,
                total_items: pagination.totalItems
            }}
            onPageChange={async (page, perPage) => {
                pagination.setCurrentPage(page);
                pagination.setPerPage(perPage);
                await pagination.paginate(filterValues);
            }}
            withAutoHeight={false}
        >
            <>
                {pagination.items.length
                    ? pagination.items?.map((event, index) =>
                        <React.Fragment key={'SbEvents' + index}>
                            <tr data-row={index}>
                                <td>
                                    <div className={"sb-events-page__td-id"}>
                                        {event.event_id}
                                    </div>
                                </td>
                                <td>{event.name}</td>
                                <td>{event.sb_performer.name}</td>
                                <td>{event.venue}</td>
                                <td>{event.city}</td>
                                <td>{event.sb_performer.category_name}</td>
                                <td>{dateToLocaleString(event.occurs_at)}</td>
                                <td>
                                    <input key={'map' + event.id} name={'to_map'} type='checkbox'
                                           defaultChecked={event.to_map}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleToMap(event.id, e.target.checked)}/>
                                </td>
                                <td>
                                    <div className="sb-events-page__icons-wrapper">
                                        {servicesIcons.map((element) => (
                                            <div>{element.name}
                                                <img
                                                    data-map-image={event.id}
                                                    src={event[element.key as keyof typeof event] ? IconYes : IconNo}
                                                    alt="event icon"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <button
                                        className={"events-page__filters__button filters-button-apply"}
                                        type={"button"}
                                        onClick={() => setShowMap(event.id)}>
                                        Map
                                    </button>
                                </td>
                            </tr>
                            <AppModal
                                title={'Map'}
                                form={<MapForm event={event} showHandle={handleMapClose}/>}
                                show={showMap === event.id}
                                showHandle={() => setShowMap(null)}
                                onClose={() => {
                                }}/>
                        </React.Fragment>
                    )
                    :
                    <div className="position-absolute top-50 start-50">
                        <AppLoader/>
                    </div>
                }
            </>
        </AppTable>
    )
}

export default SbEventsTable;
