import React, {FC, FormEvent, useEffect, useRef, useState} from 'react';
import { Form, Formik, Field, FormikValues, FormikProps, ErrorMessage } from 'formik';
import {TicketFormValidationSchema} from "./Data";
import DefaultFormikInput from "../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import "react-datepicker/dist/react-datepicker.css";
import {editTicketRequest} from "../../../../@api/User";
import {HttpFailureResponse} from "../../../../@api/Responses/HttpFailureResponse";
import "./EditTicketPage.scss"
import {useNavigate, useParams} from "react-router-dom";
import {Inventory} from "../../../../models/Inventory";
import {getInventoryById} from "../../../../@api/Inventory";
import {Tag, TagPicker} from "rsuite";
import {getSbVendorItags} from "../../../../@api/Itags";
import {StockTypes} from "../../../../enums/Skybox/StockTypes";
import {availabilityOfUpload} from "../../../../helpers/InventoryHelper";
import UploadPdfForm from "../SellTicketPage/components/UploadPdfForm/UploadPdfForm";
import AppModal from "../../../../ui/Modal/AppModal/AppModal";
import {HttpSuccessResponse} from "../../../../@api/Responses/HttpSuccessResponse";
import AppDatePicker from "../../../../ui/Inputs/AppDatePicker/AppDatePicker";
import {SplitTypes} from "../../../../enums/Skybox/SplitTypes";
import {SeatTypes} from "../../../../enums/Skybox/SeatTypes";
import TicomboInventoryForm from "../../../../components/Forms/Ticombo/TicomboInventoryForm/TicomboInventoryForm";
import {TicketData, TicomboFormData} from "../SellTicketPage/Data";
import {TicomboEvent} from "../../../../models/TicomboEvent";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/Store";
import {checkInventoryEuroStatus, getEuroStaticData} from "../../../../@api/Euro/Euro";
import {EuroCategory} from "../../../../models/Euro/EuroCategory";
import {currenciesArray} from "../../../../constants/Constants";
import { Disclosure } from '../../../../@interfaces/Skybox/Disclosure';
import { getSbDisclosures } from '../../../../@api/Disclosures';
import { SPLIT_TYPES_LABEL } from '../../../../constants/SplitTypesLabels';

const EditTicketPage: FC = () => {
    const {inventoryId} = useParams();
    const navigate = useNavigate();

    const [ticketFormData, setTicketFormData] = useState<Inventory | FormikValues>([])
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [isElectronicTicket, setIsElectronicTicket] = useState<boolean>(true);
    const [showUploadPdf, setShowUploadPdf] = useState<boolean>(false);
    const [filesLinks, setFilesLinks] = useState<string[]>([]);
    const [customSplitValues, setCustomSplitValues] = useState<boolean[]>([]);
    const [defaultDate, setDefaultDate] = useState<Date>(new Date());
    let [barcodes, setBarcodes] = useState<string[]>([]);
    const [ticomboFormData, setTicomboFormData] = useState<TicomboFormData | null>(null);
    const [ticomboEvent, setTicomboEvent] = useState<TicomboEvent | null>(null);
    const [sbTicketData, setSbTicketData] = useState<TicketData | null>(null);
    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const [euroStatus, setEuroStatus] = useState<boolean>(false);
    const [euroCategories, setEuroCategories] = useState<EuroCategory[]>([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [disclosures, setDisclosures] = useState<Disclosure[]>([]);
    const [selectedDisclosures, setSelectedDisclosures] = useState<string[]>([]);

    const generalFormRef = useRef<FormikProps<any>>(null);

    useEffect(() => {
        fetchSbVendorsItags();
        getEuroCategories();
        Promise.all([fetchSbDisclosures(), getInventoryById(Number(inventoryId))]).then((results) => {
            if (!(results[1] instanceof HttpSuccessResponse)) {
                console.error('Bad response for Inventory ID');
                return
            }
            const disclosuresList: Disclosure[] = results[0]?.data;
            const inventory = results[1]?.data?.inventory;

            setDisclosures(disclosuresList);
            setSelectedDisclosures(disclosuresList.map(a => a.skybox_value).filter(d => (inventory.disclosures || []).includes(d)));

            setTicketFormData(inventory);
            setTicomboEvent(inventory?.sb_event?.ticombo_event ?? null)

            const ticomboInventory = inventory?.ticombo_inventory;
            const ticomboSbMapping = inventory?.ticombo_sb_mapping;

            if (ticomboInventory) {
                const sbTicketData = new TicketData();
                sbTicketData.unit_cost = Number(inventory.unit_amount) as any;
                sbTicketData.in_hand_date = new Date(inventory.in_hand_date);
                sbTicketData.stock_type = inventory.stock_type;
                setSbTicketData(sbTicketData)

                setTicomboFormData({
                    fanSection: ticomboInventory.concession?.fanSection,
                    category: ticomboInventory.category,
                    customPrice: ticomboSbMapping?.custom_price ?? null,
                    setSectionStatus: ticomboInventory.set_section_status ?? 0,
                    shouldPush: ticomboInventory.should_push,
                });
            }

            const tags = inventory.tags ? inventory.tags.split(' ') : [];
            const indexOfE = Object.values(StockTypes).indexOf('E-ticket' as unknown as StockTypes);
            const indexOfM = Object.values(StockTypes).indexOf('Mobile QR' as unknown as StockTypes);

            if (inventory.tickets) {
                let existBarcodes = [];

                for (let i = 0; i < inventory.tickets.length; i++) {
                    existBarcodes.push(inventory.tickets[i].barCode);
                }

                setBarcodes(existBarcodes);
            }

            setSelectedTags(tags);
            setIsElectronicTicket(
                !(inventory.stock_type === Object.keys(StockTypes)[indexOfE] ||
                    inventory.stock_type === Object.keys(StockTypes)[indexOfM])
            );

            if (inventory.custom_split) {
                if (inventory.custom_split.length > 0) {
                    setCustomSplitValues(inventory.custom_split);
                    const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;

                    if (inventory.split_type === SplitTypes.Custom) {
                        customSplitElements(inventory.quantity, splitTypeCustom, inventory.custom_split);
                    }
                }
            }

            checkEuroStatus();
            setDefaultDate(new Date(inventory.in_hand_date))
        })
    }, [inventoryId]);

    const getEuroCategories = async () => {
        const response = await getEuroStaticData();

        if (response instanceof HttpSuccessResponse) {
            setEuroCategories(response.data.categories);
        }
    };

    const checkEuroStatus = async () => {
        if (!inventoryId) {
            return;
        }

        const response = await checkInventoryEuroStatus(inventoryId);

        if (response instanceof HttpSuccessResponse) {
            setEuroStatus(response.data.status);
        }
    };

    const setInitialSection = (): string => {
        if (!ticketFormData) {
            return '';
        }

        if (!euroStatus) {
            return ticketFormData.section;
        } else {
            let index = euroCategories.findIndex((element) =>
                element.name.toLowerCase() === ticketFormData.section.toLowerCase());
            return index > -1 ? euroCategories[index].name : '';
        }
    };

    const renderEuroCategorySelect = (value: string): JSX.Element => {
        return (
            <div>
                <label htmlFor=""
                       className="add-inventory-form-label">Category</label>
                <Field name={'section'} as="select"
                       style={{display: 'block', backgroundColor: 'white'}}
                       className={!value ? 'select-error add-inventory-form-input' : 'add-inventory-form-input'}
                >
                    <option key={'default'} value={''}
                            label={'Select category'}
                            disabled={true}
                    >
                    </option>
                    {euroCategories.map((category) => (
                        <option key={category.id} value={category.name}
                                label={category.name}>
                        </option>
                    ))}
                </Field>
            </div>
        );
    }

    const setInHandDate = (date: null | Date) => {
        ticketFormData.in_hand_date = date;

        if (sbTicketData) {
            sbTicketData.in_hand_date = date;
            setSbTicketData({...sbTicketData});
        }
    };

    const setStockType = (type: React.ChangeEvent<HTMLSelectElement>) => {
        availabilityOfUpload(
            setIsElectronicTicket,
            type.target.value,
            ticketFormData.quantity,
            ticketFormData.low_seat
        );

        if (sbTicketData) {
            sbTicketData.stock_type = type.target.value;
            setSbTicketData({...sbTicketData});
        }
    }

    const fetchSbVendorsItags = async () => {
        const data = await getSbVendorItags();
        setTags(data?.data[0]);
    }

    const vendorInventoryTagsData = tags.map((tag) => ({
        label: tag,
        value: tag,
    }));

    const fetchSbDisclosures = async () => {
        return getSbDisclosures();
    }

    const vendorInventoryDisclosuresData = disclosures.map((disclosure) => ({
        label: disclosure.description,
        value: disclosure.skybox_value,
    }));

    const handleFaceValueChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (Number(target.value) < 1) target.value = '1';
    }

    const edit = async (values: FormikValues) => {
        const requestData: any = {
            ...values,
            in_hand_date: values.in_hand_date.toISOString().split('T')[0],
            custom_split: customSplitValues,
            selected_tags: selectedTags,
            disclosures: selectedDisclosures,
            files: filesLinks,
            barcodes: barcodes
        };

        console.log('edit', {...requestData});

        if (ticomboFormData) {
            requestData.ticombo = {};

            if (ticomboFormData.shouldPush && loggedUser?.active_integrations?.includes('ticombo') &&
                loggedUser?.api_keys?.ticombo &&
                ((Number(sbTicketData!.unit_cost) > Number(ticomboFormData.customPrice) && ticomboFormData.customPrice)))
            {
                return;
            }

            if (ticomboFormData.shouldPush) {
                requestData.ticombo.category = ticomboFormData.category;
                requestData.ticombo.fan_section = ticomboFormData.fanSection;
                requestData.ticombo.set_section_status = ticomboFormData.setSectionStatus;
                requestData.ticombo.custom_price = ticomboFormData.customPrice;
            }

            requestData.ticombo.should_push = ticomboFormData.shouldPush;
        }

        console.log(requestData);
        const res = await editTicketRequest(Number(inventoryId), requestData);

        if (res instanceof HttpFailureResponse) {
            alert(res?.message);
        } else {
            navigate('/user/sell');
        }
    };

    const onCustomCheckboxChange = (event: MouseEvent) => {
        const target = event.target as HTMLInputElement;
        const parent = target.parentElement?.parentElement;
        const inputs = parent?.getElementsByTagName('input');

        if (inputs) {
            for (let i = 0; i < inputs.length; i++) {
                customSplitValues[i + 1] = inputs[i].checked;
            }
        }

        setCustomSplitValues(customSplitValues);

        const isAnyCheckboxChecked = customSplitValues.some((value) => value);

        setIsSubmitDisabled(!isAnyCheckboxChecked);
    };

    const handleSplitTypeChange = (event: MouseEvent) => {
        const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;
        splitTypeCustom.innerHTML = '';
        setCustomSplitValues([]);

        if ((event.target as HTMLSelectElement).value === SplitTypes.Custom) {
            customSplitElements(ticketFormData?.quantity, splitTypeCustom, []);
        }
    };

    const customSplitElements = (value: number, splitTypeCustom: HTMLElement, splitValues: boolean[]) => {
        for (let i = 1; i <= value; i++) {
            const label = document.createElement('label');
            const input = document.createElement('input');
            const span = document.createElement('span');

            input.type = 'checkbox';
            input.value = String(i);
            input.checked = splitValues[i] ?? false;
            input.onclick = onCustomCheckboxChange;
            span.appendChild(document.createTextNode(String(i)))
            label.appendChild(input);
            label.appendChild(span)
            splitTypeCustom.appendChild(label);
        }
    };

    const renderSeatTypeOptions = () => {
        switch (ticketFormData.seat_type) {
            case 'PIGGYBACK':
                return <option
                    value={ticketFormData.seat_type}>{SeatTypes[ticketFormData.seat_type as keyof typeof SeatTypes]}</option>;
            case 'CONSECUTIVE':
            case 'ALTERNATING':
                return (
                    <>
                        <option
                            value={ticketFormData.seat_type}>{SeatTypes[ticketFormData.seat_type as keyof typeof SeatTypes]}</option>
                        <option value="GA">GA</option>
                    </>
                );
            case 'GA':
                return <option value="GA">GA</option>;
            default:
                return null;
        }
    };

    const setTicomboDataParam = (column: keyof TicomboFormData, value: any) => {
        // @ts-ignore
        ticomboFormData[column] = value;
    }

    useEffect(() => {
        if (generalFormRef?.current?.values?.split_type === SplitTypes.Custom) {
            const isAnyCheckboxChecked = customSplitValues.some((value) => value);
            setIsSubmitDisabled(!isAnyCheckboxChecked);
        } else {
            setIsSubmitDisabled(false);
        }

    }, [customSplitValues]);

    return (
        <div className="add-inventory">
            <Formik
                innerRef={generalFormRef}
                enableReinitialize={true}
                initialValues={
                    {
                        ...ticketFormData,
                        in_hand_date: defaultDate,
                        broadcast: (ticketFormData.broadcast === 1),
                        section: setInitialSection(),
                        stock_type: ticketFormData.stock_type
                    }
                }
                validationSchema={TicketFormValidationSchema}
                onSubmit={async (values) => {
                    await edit(values);
                }}
            >
                {({isSubmitting, values, errors, touched}) =>
                    <Form>
                        <div className="add-inventory-form edit-ticket-form">
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Shown Quantity</label>
                                <DefaultFormikInput name={'shown_quantity'}
                                                    placeholder={'Shown Quantity'}
                                                    autocomplete={'off'}
                                                    class={'add-inventory-form-input'}
                                ></DefaultFormikInput>
                            </div>
                            {
                                !euroStatus ?
                                    <div>
                                        <label htmlFor="" className="add-inventory-form-label">Section*</label>
                                        <DefaultFormikInput name={'section'}
                                                            placeholder={'Section'}
                                                            autocomplete={'off'}
                                                            class={'add-inventory-form-input'}
                                                            onInput={() => availabilityOfUpload(
                                                                setIsElectronicTicket,
                                                                values.stock_type,
                                                                ticketFormData.quantity,
                                                                ticketFormData.low_seat
                                                            )}
                                        ></DefaultFormikInput>
                                    </div>
                                    :
                                    <>
                                        {renderEuroCategorySelect(values.section)}
                                    </>
                            }
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Row*</label>
                                <DefaultFormikInput name={'row'}
                                                    placeholder={'Row'}
                                                    autocomplete={'off'}
                                                    class={'add-inventory-form-input'}
                                                    onInput={() => availabilityOfUpload(
                                                        setIsElectronicTicket,
                                                        ticketFormData.stock_type,
                                                        ticketFormData.quantity,
                                                        ticketFormData.low_seat
                                                    )}
                                ></DefaultFormikInput>
                            </div>
                            {ticketFormData.seat_type === 'PIGGYBACK' && (
                                <div>
                                    <label htmlFor="" className="add-inventory-form-label">Second Row</label>
                                    <DefaultFormikInput name={'second_row'}
                                                        placeholder={'Second Row'}
                                                        autocomplete={'off'}
                                                        class={'add-inventory-form-input'}
                                    ></DefaultFormikInput>
                                </div>
                            )}
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Unit price*</label>
                                <DefaultFormikInput name={'unit_amount'}
                                                    placeholder={'Unit price'}
                                                    autocomplete={'off'}
                                                    type={'number'}
                                                    class={'add-inventory-form-input'}
                                                    onInput={(e: any) => {
                                                        if (!sbTicketData) {
                                                            return;
                                                        }

                                                        sbTicketData.unit_cost = e!?.target?.value;
                                                        setSbTicketData({...sbTicketData});
                                                    }}
                                ></DefaultFormikInput>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Face value*</label>
                                <DefaultFormikInput name={'face_value'}
                                                    type={'number'}
                                                    placeholder={'Face value'}
                                                    autoComplete={'on'}
                                                    class={'add-inventory-form-input'}
                                                    onInput={handleFaceValueChange}
                                ></DefaultFormikInput>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Currency</label>
                                <Field name={'currency_symbol'} as="select"
                                       style={{display: 'block', backgroundColor: 'white'}}
                                       className={'add-inventory-form-input'}>
                                    {currenciesArray.map(currency => (
                                        <option key={currency} value={currency}>
                                            {currency}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Public notes</label>
                                <DefaultFormikInput name={'public_notes'}
                                                    placeholder={'Public notes'}
                                                    autocomplete={'off'}
                                                    class={'add-inventory-form-input'}
                                ></DefaultFormikInput>
                            </div>
                            <div>
                                <label htmlFor=""
                                       className="add-inventory-form-label">{euroStatus ? 'VGG Listing ID' : 'Internal notes'}</label>
                                <DefaultFormikInput name={'internal_notes'}
                                                    placeholder={'Internal notes'}
                                                    autocomplete={'off'}
                                                    class={'add-inventory-form-input'}
                                                    validate={(value: string) => {
                                                        return euroStatus && !value ? 'Required' : ''
                                                    }}
                                ></DefaultFormikInput>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Tags</label>
                                <div className={'default-formik-input'}>
                                    <TagPicker
                                        className={'tag-picker'}
                                        creatable={true}
                                        onChange={(selected) => {
                                            const nonEmptyTags = selected.filter((tag: string) => tag.trim() !== '')
                                            setSelectedTags(nonEmptyTags)
                                        }}
                                        data={vendorInventoryTagsData}
                                        placeholder="Select tags or create a new one"
                                        block
                                        value={selectedTags}
                                        renderMenuItem={(label, item) => {
                                            return <>{label}</>;
                                        }}
                                        renderValue={(values, items, tags) => {
                                            return values.map((tag: any) => (
                                                <Tag key={tag}>
                                                    {tag}
                                                </Tag>
                                            ));
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Seat Type</label>
                                <Field
                                    name={'seat_type'} as="select"
                                    className={'add-inventory-form-input custom-seat-type'}
                                >
                                    {renderSeatTypeOptions()}
                                </Field>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Split Type</label>
                                <Field name={'split_type'} as="select"
                                       onClick={handleSplitTypeChange}
                                       style={{display: 'block', backgroundColor: 'white'}}
                                       className={'add-inventory-form-input'}>
                                    {SPLIT_TYPES_LABEL.map((type) => (
                                        <option value={type.key} key={type.key}>{type.label}</option>
                                    ))}
                                </Field>
                                <div className={"split-type__custom"}></div>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">Stock Type</label>
                                <Field name={'stock_type'} as="select"
                                       style={{display: 'block', backgroundColor: 'white'}}
                                       className={
                                           !values.stock_type && touched.stock_type
                                               ? 'select-error add-inventory-form-input'
                                               : 'add-inventory-form-input'
                                       }
                                       onClick={setStockType}
                                >
                                    <option value="" disabled={true}>Select type</option>
                                    <option value="HARD">Hard</option>
                                    <option value="ELECTRONIC">E-ticket</option>
                                    <option value="FLASH">Flash</option>
                                    <option value="MOBILE_SCREENCAP">Mobile QR</option>
                                    <option value="MOBILE_TRANSFER">Mobile Transfer</option>
                                    <option value="PAPERLESS">Paperless Walk-In</option>
                                    <option value="PAPERLESS_CARD">Paperless Gift Card</option>
                                </Field>
                            </div>
                            <div>
                                <label htmlFor="">{'In-hand date'}</label>
                                <AppDatePicker
                                    customClass={'form-control add-inventory-form-input '}
                                    name={'in_hand_date'}
                                    placeholder={'In-hand date'}
                                    shouldShowTime={false}
                                    sendDate={(date: any) => setInHandDate(date)}
                                />
                            </div>
                            <div className={"form-container"} style={{width: "100%"}}>
                                <label htmlFor="" className="add-inventory-form-label">Disclosures</label>
                                <div className={'default-formik-input'}>
                                    <TagPicker
                                        className={'disclosure-picker'}
                                        creatable={false}
                                        onChange={(selected) => {
                                            const nonEmptyDisclosures = selected.filter((disclosure: string) => disclosure.trim() !== '')
                                            console.log(nonEmptyDisclosures);
                                            setSelectedDisclosures(nonEmptyDisclosures)
                                        }}
                                        data={vendorInventoryDisclosuresData}
                                        placeholder="Select disclosures"
                                        block
                                        placement={'autoVerticalStart'}
                                        value={selectedDisclosures}
                                        renderMenuItem={(label, item) => {
                                            return <>{label}</>;
                                        }}
                                        renderValue={(values, items, tags) => {
                                            return values.map((disclosure: any) => (
                                                <Tag key={disclosure}>
                                                    {disclosure}
                                                </Tag>
                                            ));
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="" className="add-inventory-form-label">PDF/Barcode</label>
                                <button
                                    className="filters-button-reset"
                                    type="button"
                                    disabled={isElectronicTicket}
                                    onClick={() => setShowUploadPdf(true)}
                                >
                                    Upload
                                </button>
                            </div>
                            <div className={'checkbox-field'}>
                                <label className="add-inventory-form-label">Broadcast</label>
                                <Field name={'broadcast'} type={'checkbox'}/>
                            </div>
                        </div>
                        {
                            (ticomboFormData && ticomboEvent && sbTicketData && loggedUser!.api_keys?.ticombo)
                                ?
                                <div className={'w-100 mt-4'}>
                                    <h2 className={'mb-4'}>Push to Platform</h2>
                                    {
                                        ticomboFormData && ticomboEvent && sbTicketData && loggedUser!.api_keys?.ticombo &&
                                        <div className={"mt-3"}>
                                            <TicomboInventoryForm ticomboFormData={ticomboFormData}
                                                                  ticomboEvent={ticomboEvent}
                                                                  sbTicketData={sbTicketData}
                                                                  isEditForm={true}
                                                                  dispatch={setTicomboDataParam}
                                            />
                                        </div>
                                    }
                                </div>
                                : null
                        }

                        <button className="add-inventory-form-submit-btn mt-3" type="submit"
                                disabled={isSubmitting || isSubmitDisabled}>Submit
                        </button>
                    </Form>
                }
            </Formik>

            <AppModal
                title={'Ticket Uploader'}
                form={<UploadPdfForm
                    quantity={ticketFormData.quantity}
                    lowSeat={ticketFormData.low_seat}
                    section={(document.getElementsByName('section')[0] as HTMLInputElement)?.value}
                    row={(document.getElementsByName('row')[0] as HTMLInputElement)?.value}
                    barcodes={barcodes}
                    setBarcodes={setBarcodes}
                    filesLinks={filesLinks}
                    setFilesLinks={setFilesLinks}
                    showHandle={() => setShowUploadPdf(false)}
                />}
                show={showUploadPdf}
                showHandle={() => setShowUploadPdf(false)}
                onClose={() => {
                }}
            />
        </div>
    )
}

export default EditTicketPage;
