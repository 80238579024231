import React, {FC, useEffect, useState} from "react";
import AppTable from "../../../ui/Table/AppTable/AppTable";
import AppButton from "../../../ui/Buttons/AppButton/AppButton";
import usePagination from "../../../helpers/UsePagination";
import {Customer} from "../../../models/Customer";
import {
    customerSearchByDisplayName,
    customerSearchById,
    customersPaginate,
    deleteCustomer,
} from "../../../@api/Customers";
import {Accordion, Container} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {handleOnFiltersChange, handleReset} from "../../../helpers/Filters";
import AppSmartSearch from "../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {CustomerTypes} from "../../../enums/CustomerTypes";
import './CustomersPage.scss';
import AppModal from "../../../ui/Modal/AppModal/AppModal";
import CustomerOrVendorForm from "./CustomerOrVendorForm/CustomerOrVendorForm";
import {getAllPossibleTags, getCustomerTags} from "../../../@api/CustomTags";
import {HttpSuccessResponse} from "../../../@api/Responses/HttpSuccessResponse";
import {PaymentMethods} from "../../../enums/PaymentMethods";
import CustomerSalesModal from "./CustomerSalesModal/CustomerSalesModal";
import DeleteConfirmationModal from "../../../ui/Modal/DeleteConfiramationModal/DeleteConfirmationModal";
import {CountryTypes} from "../../../enums/Skybox/CountryTypes";
import AppLoader from "../../../ui/Loader/AppLoader/AppLoader";

interface FilterProps {
    id: string;
    display_name: string;
    sort_by: string;
    type: string;
}

const CustomersPage: FC = () => {
    const filterInitial: FilterProps = {
        id: '',
        display_name: '',
        sort_by: 'ASC',
        type: ''
    };

    const tableHeaders = [
        'Customer ID',
        'Display name',
        'Type',
        'Email',
        'Last name',
        'First name',
        'Company',
        'Street address',
        'Apartment / Floor',
        'City',
        'State',
        'Country',
        'Postal code',
        'Phone',
        'Customer credit',
        'Payment Method',
        'Customer tags',
        'Actions'
    ];

    const smartInputs = [
        {
            name: 'id',
            placeholder: 'Search by ID',
            requestFunction: customerSearchById,
            valuesKey: 'id'
        },
        {
            name: 'display_name',
            placeholder: 'Search by display name',
            requestFunction: customerSearchByDisplayName,
            valuesKey: 'display_name'
        }
    ];

    const [filterValues, setFilterValues] = useState<FilterProps>(filterInitial);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(undefined);
    const [isEditMode, setIsEditMode] = useState(false);
    const [tags, setTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>(selectedCustomer?.customer_tags || []);
    const [isLoading, setIsLoading] = useState(false);
    const [salesModalOpen, setSalesModalOpen] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const pagination = usePagination<Customer>(customersPaginate);

    useEffect(() => {
        pagination.paginate();
    }, []);

    const handleCustomerModal = async () => {
        setIsModalOpen(!isModalOpen);
        setIsEditMode(false);

        await pagination.paginate();

        getAllPossibleTags().then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setTags(res.data);
            }
        });
    };

    const handleConfirmationModal = (customer: Customer) => {
        setSelectedCustomer(customer);
        setShowConfirmationModal(!showConfirmationModal);
    };

    const closeConfirmationModal = () => {
        setSelectedCustomer(undefined);
        setShowConfirmationModal(false);
    };

    const handleSalesModal = (customer: Customer) => {
        setSelectedCustomer(customer);
        setSalesModalOpen(true);
    };

    const handleCloseSalesModal = () => {
        setSelectedCustomer(undefined);
        setSalesModalOpen(false);
    };

    const handleEditCustomer = async (customer: Customer) => {
        setSelectedCustomer(customer);
        setIsModalOpen(!isModalOpen);
        setIsEditMode(true);

        getCustomerTags(customer?.id).then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setTags(res.data);
            }
        });
    };

    const handleDeleteCustomer = async (customer: Customer) => {
        setIsLoading(true);

        const res = await deleteCustomer(Number(customer));

        await pagination.paginate();

        res instanceof HttpSuccessResponse ? closeConfirmationModal() : alert(res.message);

        setIsLoading(false);
    }

    return (
        <Container className={'admin-users-page events'}>
            {!pagination.isLoading ?
                <div className={'w-100 mt-3'}>
                    <div style={{width: "fit-content"}} onClick={() => handleCustomerModal()}>
                        <AppButton text={'Add customer'}></AppButton>
                    </div>
                    <div className="mb-3 mt-3">
                        <Accordion>
                            <Accordion.Item eventKey={'0'}>
                                <Accordion.Header>
                                    Filters
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Formik
                                        initialValues={filterInitial}
                                        onSubmit={async (values) => {
                                            pagination.setPerPage(pagination.perPage);
                                            await pagination.paginate(values);
                                        }}
                                    >
                                        {({resetForm}) => {
                                            return (
                                                <Form className="table__filters"
                                                      onChange={(event) => handleOnFiltersChange(event, filterValues, setFilterValues)}>
                                                    <div className="table__filters__wrapper">
                                                        {smartInputs.map((input, index) => (
                                                            <AppSmartSearch
                                                                key={index + input.name}
                                                                name={input.name}
                                                                placeholder={input.placeholder}
                                                                requestFunction={input.requestFunction}
                                                                valuesKey={input.valuesKey}
                                                            />
                                                        ))}
                                                        <Field name={'type'} as="select">
                                                            <option value="">Type</option>
                                                            {Object.entries(CustomerTypes).map((key, index) => (
                                                                <option key={String(index) + key}
                                                                        value={key[0]}>{key[1]}</option>
                                                            ))}
                                                        </Field>
                                                        <button type="reset"
                                                                className="filters-button-reset"
                                                                onClick={async () => {
                                                                    await handleReset(resetForm, filterInitial, pagination, setFilterValues);
                                                                }}
                                                        >
                                                            Reset
                                                        </button>
                                                        <button type="submit" className="filters-button-apply">
                                                            Apply Filters
                                                        </button>
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <AppTable
                        columns={tableHeaders}
                        pagination={{
                            lastPage: pagination.lastPage,
                            currentPage: pagination.currentPage,
                            total_items: pagination.totalItems
                        }}
                        onPageChange={(page, perPage) => {
                            pagination.setCurrentPage(page);
                            pagination.setPerPage(perPage);
                            pagination.paginate(filterValues);
                        }}>
                        <>
                            {pagination.items.length ? (
                                    pagination.items?.map((customer, index) => (
                                        <tr data-row={index} key={customer.id}>
                                            <td>{customer.id}</td>
                                            <td>{customer.display_name}</td>
                                            <td>{CustomerTypes[customer.type as keyof typeof CustomerTypes]}</td>
                                            <td>{customer.email}</td>
                                            <td>{customer.last_name}</td>
                                            <td>{customer.first_name}</td>
                                            <td>{customer.company}</td>
                                            <td>{customer.street_address}</td>
                                            <td>{customer.apartment_floor}</td>
                                            <td>{customer.city}</td>
                                            <td>{customer.state}</td>
                                            <td>{CountryTypes[customer.country as keyof typeof CountryTypes]}</td>
                                            <td>{customer.postal_code}</td>
                                            <td>{customer.phone}</td>
                                            <td>{customer.customer_credit}</td>
                                            <td>{PaymentMethods[customer.payment_method as keyof typeof PaymentMethods]}</td>
                                            <td>{customer.customer_tags?.join(', ')}</td>
                                            <td>
                                                <div className={'customers_actions'}>
                                                    {
                                                        !customer.share ?
                                                            <>
                                                                <div onClick={() => handleEditCustomer(customer)}>
                                                                    <AppButton text={'Edit'}/>
                                                                </div>
                                                                <div onClick={() => handleConfirmationModal(customer)}>
                                                                    <AppButton text={'Delete'}/>
                                                                </div>
                                                            </>
                                                            : <></>
                                                    }
                                                    <div onClick={() => handleSalesModal(customer)}>
                                                        <AppButton text={'View sales'}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) :
                                <tr>
                                    <td className={'app-paginated-table_td-empty'} colSpan={tableHeaders.length}>
                                        No results
                                    </td>
                                </tr>
                            }

                            <AppModal
                                headerClassName={'customers-modal-header'}
                                headerTitleClassName={'customers-modal-header__title'}
                                size={'lg'}
                                backdrop={'static'}
                                keyboard={false}
                                title={isEditMode ? 'Edit customer' : 'Add customer'}
                                form={
                                    <CustomerOrVendorForm
                                        onClose={() => handleCustomerModal()}
                                        tags={tags}
                                        selectedTags={selectedTags}
                                        setSelectedTags={setSelectedTags}
                                        customer={isEditMode ? selectedCustomer : undefined}
                                        editMode={isEditMode}
                                    />
                                }
                                show={isModalOpen}
                                showHandle={handleCustomerModal}
                                onClose={() => handleCustomerModal}
                            />
                            <AppModal
                                headerStyle={{display: "none"}}
                                title={''}
                                size={'sm'}
                                closeButton={false}
                                form={
                                    <DeleteConfirmationModal
                                        disabled={isLoading}
                                        customer={selectedCustomer}
                                        confirmDeleteFunc={handleDeleteCustomer}
                                        closeModalFunc={closeConfirmationModal}
                                        modalTitle={'Customer'}
                                    />
                                }
                                show={showConfirmationModal}
                                showHandle={closeConfirmationModal}
                                onClose={() => closeConfirmationModal}
                            />
                            <AppModal
                                title={'Sold inventory'}
                                customClass={'purchased-inventories-modal'}
                                centered={true}
                                form={
                                    <CustomerSalesModal
                                        customer={selectedCustomer!}
                                    />
                                }
                                show={salesModalOpen}
                                showHandle={handleCloseSalesModal}
                                onClose={() => {
                                }}
                            />
                        </>
                    </AppTable>
                </div>
                :
                <AppLoader/>
            }
        </Container>
    )
}

export default CustomersPage;
