import requestWrapper from "./RequestWrapper";
import {GlobalStatusCodesEnum} from "../enums/ResponseStatuses/GlobalStatusCodesEnum";
import axios from "axios";

const urlPrefix = 'p1_series';

export const getSeries = () => {
    return requestWrapper<GlobalStatusCodesEnum, string[]>(
        axios.get(`${urlPrefix}`)
    );
};
