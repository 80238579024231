import React, {FC, useRef, useState} from "react";
import './HeaderMobileNavigation.scss';
import {Link, useNavigate} from "react-router-dom";
import {Accordion} from "react-bootstrap";
import {NavigationItem} from "../../MainInterface/HeaderNavigation/NavigationItems";
import useHandleOutsideClick from "../../../hooks/useHandleOutsideClick";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/Store";
import {RolesEnum} from "../../../enums/RolesEnum";

interface Props {
    handleColors: (id: number | null) => void;
    navigationItems: NavigationItem[];
}

const HeaderMobileNavigation: FC<Props> = ({handleColors, navigationItems}) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();
    const navigationRef = useRef<HTMLDivElement>(null);

    const handleLinkClick = (path?: string) => {
        if (path) {
            navigate(path);
        }

        setIsMenuOpen(!isMenuOpen);
        handleColors(null);
    };

    useHandleOutsideClick(navigationRef, () => {
        handleColors(null);
        setIsMenuOpen(false);
    });

    return (
        <>
            <div className={'mobile-navigation'}>
                <div onClick={() => {
                    handleColors(1)
                    setIsMenuOpen(true)
                }} className={'mobile-navigation__icon'}></div>
            </div>
            <div className={isMenuOpen ? 'menu-open' : 'menu-closed'} ref={navigationRef}>
                <div className={'menu-header'}>
                    <span onClick={() => handleLinkClick()}>
                        <Link className="header-logo" to="/">Broker B2B</Link>
                    </span>
                    <div onClick={() => handleLinkClick()} className={'mobile-navigation__icon_close'}></div>
                </div>
                <div className={'menu-items'}>
                    <div className={'link-container'}>
                        {user!.role_id === RolesEnum.User && (
                            <span onClick={() => handleLinkClick()}>
                                 <Link to={'/user/sell'}>Inventory</Link>
                            </span>
                        )}
                    </div>
                    <div className={'accordion-container'}>
                        <Accordion ref={navigationRef}>
                            {navigationItems.map((item, index) => (
                                <Accordion.Item key={item.itemId} eventKey={item.itemId.toString()}>
                                    <Accordion.Header>{item.title}</Accordion.Header>
                                    <Accordion.Body>
                                        {item.items.map((subItem, subIndex) => {
                                            if (subItem.condition && !subItem.condition()) {
                                                return null;
                                            }

                                            return (
                                                <p key={index} onClick={() => handleLinkClick(subItem.path)}>
                                                    {subItem.itemTitle}
                                                </p>
                                            )
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                    <div className={'link-container'}>
                        {user!.role_id === RolesEnum.User && (
                            <span onClick={() => handleLinkClick()}>
                                <Link to="/user/teammates">Teammates</Link>
                             </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderMobileNavigation;
