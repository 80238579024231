import React, {FC, FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/Store";
import {Field, Form, Formik, FormikState, FormikValues} from "formik";
import {TicketData, TicketFormValidationSchema, TicomboFormData} from "./Data";
import DefaultFormikInput from "../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import "react-datepicker/dist/react-datepicker.css";
import {HttpSuccessResponse} from "../../../../@api/Responses/HttpSuccessResponse";
import {GlobalStatusCodesEnum} from "../../../../enums/ResponseStatuses/GlobalStatusCodesEnum";
import {AuthResponseStatusesEnum} from "../../../../enums/ResponseStatuses/AuthResponseStatusesEnum";
import {HttpFailureResponse} from "../../../../@api/Responses/HttpFailureResponse";
import {FormikErrors} from "formik/dist/types";
import FormikDateInput from "../../../../components/Inputs/FormikDateInput/FormikDateInput";
import {setEvent} from "../../../../store/Reducers/Event";
import "./SellTicketPage.scss"
import {clearNewInv, setNewEvent, setNewTicket} from "../../../../store/Reducers/Inventory";
import LTGDeliveryForm from '../../../../components/SellPage/LTGDeliveryForm/LTGDeliveryForm';
import useHandleSbEventsPaginationScroll from "../../../../hooks/useHandleSbEventsPaginationScroll";
import {SbEventsFilters} from "../../../../@interfaces/SbEventsFilters";
import {useDynamicSortOptions} from "../../../../hooks/useDynamicSortOptions";
import FindMatches from "./components/FindMatches/FindMatches";
import useWindowWidth from "../../../../hooks/useHandleWindowResize";
import {RolesEnum} from "../../../../enums/RolesEnum";
import {MOBILE_WIDTH} from "../../../../constants/Layout";
import {useLocation, useNavigate} from "react-router-dom";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import {getOccupiedSeats} from "../../../../@api/Inventory";
import {Tag, TagPicker} from "rsuite";
import {getSbVendorItags} from "../../../../@api/Itags";
import AppModal from "../../../../ui/Modal/AppModal/AppModal";
import UploadPdfForm from "./components/UploadPdfForm/UploadPdfForm";
import {availabilityOfUpload} from "../../../../helpers/InventoryHelper";
import {UserVendorCreation} from "../../../../models/UserVendor";
import {postUserVendor, userVendorSearchByDisplayName} from "../../../../@api/UserVendors";
import {setUserVendorTags} from "../../../../@api/VendorTags";
import {getAllPossibleTags} from "../../../../@api/CustomTags";
import RequestEventForm from "./components/RequestEventForm/RequestEventForm";
import AppDatePicker from "../../../../ui/Inputs/AppDatePicker/AppDatePicker";
import {getEuroStaticData} from "../../../../@api/Euro/Euro";
import {EuroVendor} from "../../../../models/Euro/EuroVendor";
import {EuroCategory} from "../../../../models/Euro/EuroCategory";
import {checkCompleteOccurrenceOfNumberArrays} from "../../../../helpers/ArrayOperations";
import StubhubParams from "../components/StubhubParams/StubhubParams";
import {SbEvent} from "../../../../models/Event";
import {dateToLocaleString} from "../../../../helpers/DateFormatter";
import AppButton from "../../../../ui/Buttons/AppButton/AppButton";
import TicomboInventoryForm from "../../../../components/Forms/Ticombo/TicomboInventoryForm/TicomboInventoryForm";
import {sendTicketRequest} from "../../../../@api/User";
import CustomerOrVendorForm from "../../CustomersPage/CustomerOrVendorForm/CustomerOrVendorForm";
import {currenciesArray} from "../../../../constants/Constants";
import AppLoader from "../../../../ui/Loader/AppLoader/AppLoader";
import MatchCard from "../components/MatchCard/MatchCard";
import AppSmartSearch from "../../../../ui/Inputs/AppSmartSearch/AppSmartSearch";
import {performersNotPaginated} from "../../../../@api/Performer";
import { Disclosure } from '../../../../@interfaces/Skybox/Disclosure';
import { getSbDisclosures } from '../../../../@api/Disclosures';
import { SplitTypes } from '../../../../enums/Skybox/SplitTypes';
import { SPLIT_TYPES_LABEL } from '../../../../constants/SplitTypesLabels';

const SellTicketPage: FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const eventId = queryParams.get("eventId");
    const filterInitial: SbEventsFilters = {name: '', from_date: new Date(), venue: '', city: '', id: eventId};
    const ticketInitialFormData = new TicketData();
    const [ticketFormData, setTicketFormData] = useState(new TicketData());
    const [ticomboFormData, setTicomboFormData] = useState(new TicomboFormData());
    const windowWidth = useWindowWidth();
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const dispatch = useDispatch();

    const loggedUser = useSelector((state: RootState) => state.auth.user);
    const event = useSelector((state: RootState) => state.event);

    const [currentCardId, setCurrentCardId] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
    const [seatType, setSeatType] = useState(ticketInitialFormData.seat_type || 'CONSECUTIVE');
    const [customSplitValues, setCustomSplitValues] = useState<boolean[]>([]);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedVendorTags, setSelectedVendorTags] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isElectronicTicket, setIsElectronicTicket] = useState<boolean>(true);
    const [showUploadPdf, setShowUploadPdf] = useState<boolean>(false);
    const [filesLinks, setFilesLinks] = useState<string[]>([]);
    const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false)
    let [barcodes, setBarcodes] = useState<string[]>([]);
    const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);
    const [euroCategories, setEuroCategories] = useState<EuroCategory[]>([]);
    const [euroEventIds, setEuroEventIds] = useState<number[]>([]);
    const [euroCategoriesShowStatus, setEuroCategoriesShowStatus] = useState<boolean>(false)
    const [selectedEvents, setSelectedEvents] = useState<SbEvent[]>([]);
    const [disclosures, setDisclosures] = useState<Disclosure[]>([]);
    const [selectedDisclosures, setSelectedDisclosures] = useState<Disclosure[]>([]);

    const paginationRef = useRef<null | any>(null);

    const handleScroll = useHandleSbEventsPaginationScroll(paginationRef, filterInitial);
    const {setSortOptions, sortFunction} = useDynamicSortOptions();
    const [currentDate] = useState<Date>(new Date());

    const navigate = useNavigate();

    useEffect(() => {
        fetchSbVendorsItags();
        fetchSbDisclosures();
        checkEuroVendors();

        return () => {
            dispatch(clearNewInv());
        }
    }, []);

    const fetchSbVendorsItags = async () => {
        const data = await getSbVendorItags();
        setTags(data?.data[0]);
    }

    const checkEuroVendors = async () => {
        const response = await getEuroStaticData();
        if (response instanceof HttpSuccessResponse) {
            const vendor = response.data.vendors.find(
                (vendor: EuroVendor) => vendor.sb_vendor_id === loggedUser?.vendor_id
            );

            if (vendor) {
                setEuroCategories(response.data.categories);
                const euroEventIds = response.data.events.map(event => event.sb_event_id)
                setEuroEventIds(euroEventIds);
            }
        }
    }

    const vendorInventoryTagsData = tags.map((tag) => ({
        label: tag,
        value: tag,
    }));


    const fetchSbDisclosures = async () => {
        const data = await getSbDisclosures();
        setDisclosures(data?.data);
    }

    const vendorInventoryDisclosuresData = disclosures.map((disclosure) => ({
        label: disclosure.description,
        value: disclosure.skybox_value,
    }));

    const searchMatch = (event: FormikValues) => {
        handleScroll.filterEvents(event)
    }

    useEffect(() => {
        const currentEvent = document.querySelector(`[data-event-id="${eventId}"]`) as HTMLElement;

        if (currentEvent) {
            currentEvent.click();
        }

    }, [eventId]);

    const showTicomboForm = useMemo(
        () => event.ticombo_event && loggedUser!.api_keys?.ticombo,
        [event, loggedUser]
    );

    const validateNotes = (value: string) => {
        let error;
        let euroSelectedEvents = euroEventIds.filter(event => selectedEventIds.includes(event));
        error = euroSelectedEvents.length > 0 && euroEventIds.length > 0 && value.length === 0 ? 'Required' : '';
        return error;
    }

    const setInHandDate = (date: null | Date) => {
        ticketFormData.in_hand_date = date;
    }

    const updateSelectedEventIdsComparingEuro = (eventId: number) => {
        if (selectedEventIds.includes(eventId)) {
            return selectedEventIds.filter(id => id !== eventId);
        }

        if (selectedEventIds.length === 0) {
            return [eventId];
        }

        const eventEuroStatus = euroEventIds.includes(eventId);
        const selectedEventsAlreadyContainsEuro = checkCompleteOccurrenceOfNumberArrays(selectedEventIds, euroEventIds).length === 0;

        let checkEventsInOneGroup = (selectedEventsAlreadyContainsEuro && eventEuroStatus) ||
            (!selectedEventsAlreadyContainsEuro && !eventEuroStatus);

        if (checkEventsInOneGroup) {
            return [...selectedEventIds, eventId]
        }

        return [...selectedEventIds];
    }

    const toggleMatch = (data: any) => {
        setTicomboFormData(new TicomboFormData());
        setTicketFormData(new TicketData());

        // Before adding euro categories for euro events and vendors
        // const updatedSelectedEventIds = selectedEventIds.includes(data.id)
        //     ? selectedEventIds.filter(id => id !== data.id)
        //     : [...selectedEventIds, data.id];

        const updatedSelectedEventIds = updateSelectedEventIdsComparingEuro(data.id)
        setSelectedEventIds(updatedSelectedEventIds);
        checkEuroEventsWereChosen(updatedSelectedEventIds, euroEventIds);

        setSelectedEvents(prevSelectedEvents => {
            const eventIndex = prevSelectedEvents.findIndex(event => event.id === data.id);
            const selectedEventIdExists = updatedSelectedEventIds.findIndex(id => id === data.id);

            if (eventIndex !== -1 && selectedEventIdExists === -1) {
                return prevSelectedEvents.filter(event => event.id !== data.id);
            } else if (eventIndex === -1 && selectedEventIdExists !== -1) {
                return [...prevSelectedEvents, data];
            } else {
                return [...prevSelectedEvents];
            }
        });

        setCurrentCardId(data.id);
        dispatch(setNewEvent(data))
        dispatch(setEvent({
            event_venue: data.venue,
            event_city: data.city,
            event: data.name,
            id: data.id,
            date: data.occurs_at,
            ticombo_event: data.ticombo_event,
            stubhub_event: data.stubhub_event
        }));

        if (windowWidth <= 1024 && isOpenSidebar) {
            setIsOpenSidebar(false)
        }

    }

    const checkEuroEventsWereChosen = (selectedEvents: number[], euroEvents: number[]) => {
        const checkEventIds = checkCompleteOccurrenceOfNumberArrays(selectedEvents, euroEvents);

        if (euroCategories.length > 0 && checkEventIds.length === 0 && selectedEvents.length !== 0) {
            setEuroCategoriesShowStatus(true);
        } else {
            setEuroCategoriesShowStatus(false);
        }
    }

    const successResponse = (res: HttpSuccessResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum>) => {
        setIsSubmitted(false);

        navigate('/user/sell');
    };

    const failureResponse = (
        res: HttpFailureResponse<GlobalStatusCodesEnum & AuthResponseStatusesEnum>,
        setErrors: (errors: FormikErrors<any>) => void,
    ) => {
        setErrors(res.validation ?? {});
        alert(res.message);
    };

    const resetFilterForm = async () => {
        setIsBtnDisabled(true);
        await handleScroll.resetEvents();
        setIsBtnDisabled(false);
    };

    const inHandDay = (event: { event?: string, id?: number, date?: string }) => {
        let date = new Date();

        if (event.date) {
            date = new Date(event.date);
        }

        date.setDate(date.getDate() - 3);

        return date;
    };

    const onCustomCheckboxChange = (event: MouseEvent) => {
        const target = event.target as HTMLInputElement;

        customSplitValues[Number(target.defaultValue)] = target.checked;
        setCustomSplitValues(customSplitValues);
    };

    const handleQuantityChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        if (Number(target.value) < 1) target.value = '1';
        if (Number(target.value) > 99) target.value = '99';

        customSplitValues.splice(0, customSplitValues.length);

        const splitType = document.getElementsByName('split_type')[0] as HTMLSelectElement;
        const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;

        splitTypeCustom.innerHTML = '';

        if (splitType.value === SplitTypes.Custom) {
            customSplitElements(Number(target.value), splitTypeCustom);
        }
    };

    const handleSplitTypeChange = (event: MouseEvent) => {
        const splitTypeCustom = document.getElementsByClassName('split-type__custom')[0] as HTMLElement;

        splitTypeCustom.innerHTML = '';
        customSplitValues.splice(0, customSplitValues.length);

        if ((event.target as HTMLSelectElement).value === SplitTypes.Custom) {
            const quantity = document.getElementsByName('quantity')[0] as HTMLInputElement;

            customSplitElements(Number(quantity.value), splitTypeCustom);
        }
    };

    const handleFaceValueChange = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        if (Number(target.value) < 1) target.value = '1';
    };

    const customSplitElements = (value: number, splitTypeCustom: HTMLElement) => {
        for (let i = 1; i <= value; i++) {
            const label = document.createElement('label');
            const input = document.createElement('input');
            const span = document.createElement('span');

            input.type = 'checkbox';
            input.value = String(i);
            input.onclick = onCustomCheckboxChange;
            span.appendChild(document.createTextNode(String(i)))
            label.appendChild(input);
            label.appendChild(span)
            splitTypeCustom.appendChild(label);
        }
    };

    const mobileClass = {
        container: windowWidth > MOBILE_WIDTH ? '' : 'form-container',
    };

    const onSeatFocus = () => {
        const section = document.getElementsByName('section')[0] as HTMLInputElement;
        const row = document.getElementsByName('row')[0] as HTMLInputElement;

        if (section.value && row.value && event.id) {
            getOccupiedSeats({eventId: event.id, row: row.value, section: section.value})
                .then((response) => {
                    if (response instanceof HttpSuccessResponse && response.data.length > 0) {
                        const element = document.getElementsByClassName('seats-tooltip')[0] as HTMLElement;

                        if (element) {
                            element.innerText = 'Occupied seats: \n' + response.data.join(', ');
                            setIsComponentVisible(true);
                        }
                    }
                })
        }
    };

    const submitVendor = async (values: UserVendorCreation) => {
        setIsLoading(true)
        const res = await postUserVendor({...values, vendor_tags: selectedVendorTags});

        if (res instanceof HttpSuccessResponse) {
            setIsModalOpen(false)
        }

        await setUserVendorTags(selectedVendorTags, res.data.id);

        setIsLoading(false);
    };

    const handleVendorModal = async () => {
        setIsModalOpen(!isModalOpen);

        getAllPossibleTags().then((res) => {
            if (res instanceof HttpSuccessResponse) {
                setTags(res.data);
            }
        });
    };

    const handleSubmit = async (
        values: FormikValues,
        setErrors: (errors: FormikErrors<FormikValues>) => void,
        resetForm: (nextState?: (Partial<FormikState<any>> | undefined)) => void
    ) => {
        const vendorInput = document.querySelector('input[data-entity-id]') as HTMLInputElement;
        const data = {
            ...values,
            selected_tags: selectedTags,
            disclosures: selectedDisclosures,
            in_hand_date: values.in_hand_date.toISOString().split('T')[0],
            custom_split: customSplitValues,
            files: filesLinks,
            barcodes: barcodes,
            user_vendor_id: Number(vendorInput.dataset.entityId)
        } as any;

        if (ticomboFormData) {
            data.ticombo = {};

            if (ticomboFormData.shouldPush && (
                (Number(ticketFormData.unit_cost) > Number(ticomboFormData.customPrice) && ticomboFormData.customPrice)
            )) {
                return;
            }

            if (ticomboFormData.shouldPush) {
                data.ticombo.category = ticomboFormData.category;
                data.ticombo.fan_section = ticomboFormData.fanSection;
                data.ticombo.set_section_status = ticomboFormData.setSectionStatus;
                data.ticombo.custom_price = ticomboFormData.customPrice;
            }

            data.ticombo.should_push = ticomboFormData.shouldPush;
        }

        // fix for inventories witch have to push to ticombo with default ticombo account
        if (!loggedUser?.api_keys?.ticombo) {
            data.ticombo.should_push = true;
        }

        setIsSubmitted(true);

        const res = await sendTicketRequest(data);

        if (res?.status) {
            dispatch(setNewTicket(data));
            resetForm();
        }

        return res instanceof HttpSuccessResponse ? successResponse(res) : failureResponse(res, setErrors);
    };

    const setStockType = (type: any) => {
        ticketFormData.stock_type = type.target.value;
    }

    const handleRequestEventModal = () => {
        setRequestModalOpen(!requestModalOpen);
    };

    const removeEvent = (eventId: number) => {
        setSelectedEvents(prevSelectedEvents => prevSelectedEvents.filter(event => event.id !== eventId));

        setSelectedEventIds(prevSelectedEventIds =>
            prevSelectedEventIds.filter(id => id !== eventId)
        );
    };

    const setTicomboDataParam = (column: keyof TicomboFormData, value: any) => {
        // @ts-ignore
        ticomboFormData[column] = value;
    }

    const renderEuroCategorySelect = (value: string, touched?: boolean): JSX.Element => {
        return (
            <div className={mobileClass.container}>
                <label htmlFor=""
                       className="add-inventory-form-label">Category</label>
                <Field name={'section'} as="select"
                       style={{display: 'block', backgroundColor: 'white'}}
                       className={!value && touched ? 'select-error add-inventory-form-input' : 'add-inventory-form-input'}
                >
                    <option key={'default'} value={''}
                            label={'Select category'}
                            disabled={true}
                    >
                    </option>
                    {euroCategories.map((category) => (
                        <option key={category.id} value={category.name}
                                label={category.name}>
                        </option>
                    ))}
                </Field>
            </div>
        );
    }

    const GetEvents = () => {
        const list = document.getElementsByClassName('matches-list')[0];

        if (handleScroll.events && handleScroll.events.length && !handleScroll.isLoading) {
            list?.classList.remove('overflow-hidden');
            return (<>
                {handleScroll.events.sort(sortFunction).map((data) =>
                    <MatchCard key={data.event_id} event={data}
                               currentEventId={currentCardId}
                               clickFunc={() => toggleMatch(data)}
                               isSelected={selectedEventIds.includes(data.id)}
                    />
                )}
            </>)
        } else if (!handleScroll.isLoading) {
            list?.classList.remove('overflow-hidden');
            return (<div>Event not found. Please enter a different value</div>)
        } else {
            list?.classList.add('overflow-hidden');
            return (<AppLoader/>)
        }
    };

    return (
        <>
            {isOpenSidebar && windowWidth <= 1024 ? (
                <div className="buy-page-overlay"></div>
            ) : null}
            <div className="find-events-sidebar">
                <div className="find-events-sidebar-toggle" onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
                    {isOpenSidebar ? <span>&#171;</span> : <span>&#187;</span>}
                </div>
                <div className={isOpenSidebar ? 'find-events find-events-expand' : 'find-events-collapse'}>
                    {
                        isOpenSidebar
                            ? <>
                                <div className="matches matches-add-inventory">
                                    <div className="matches-filters">
                                        <div className="matches-header matches-header-add-inventory">
                                            <h3 className="matches-title">
                                                Matches
                                            </h3>
                                            <select
                                                onChange={(e) => setSortOptions(e.target.value)}
                                                defaultValue={'occurs_at'}
                                                className="matches-sort"
                                            >
                                                <option value="occurs_at">Sort by date &darr;</option>
                                                <option value="-occurs_at">Sort by date &uarr;</option>
                                                <option value="name">Sort by name &darr;</option>
                                                <option value="-name">Sort by name &uarr;</option>
                                            </select>
                                        </div>
                                        <hr/>
                                        <Formik
                                            initialValues={filterInitial}
                                            onSubmit={async (values) => {
                                                const data = {
                                                    ...values
                                                } as any;
                                                await searchMatch(data);
                                            }}
                                            innerRef={paginationRef}
                                        >
                                            {({isSubmitting, values, handleChange}) =>
                                                <Form className="filters-form">
                                                    <DefaultFormikInput
                                                        name={'name'}
                                                        placeholder={'Search events by name'}
                                                        autoComplete={'on'}
                                                        class={'filters-search'}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                    ></DefaultFormikInput>
                                                    <DefaultFormikInput
                                                        name={'venue'}
                                                        placeholder={'Search events by venue'}
                                                        autoComplete={'on'}
                                                        class={'filters-search'}
                                                        onChange={handleChange}
                                                        value={values.venue}
                                                    ></DefaultFormikInput>
                                                    <DefaultFormikInput
                                                        name={'city'}
                                                        placeholder={'Search events by city'}
                                                        autoComplete={'on'}
                                                        class={'filters-search'}
                                                        onChange={handleChange}
                                                        value={values.city}
                                                    ></DefaultFormikInput>
                                                    <div className="events-page__filters__component">
                                                        <AppSmartSearch
                                                            name={'performer'}
                                                            placeholder={'Search by performer'}
                                                            requestFunction={performersNotPaginated}
                                                            valuesKey={'name'}
                                                        />
                                                    </div>
                                                    <div className="filters-date">
                                                        <FormikDateInput
                                                            name={'from_date'}
                                                            placeholderText={'From date'}
                                                            className={''}
                                                            autoComplete={'off'}
                                                            onChange={handleChange}
                                                            value={values.to_date}
                                                        />
                                                        <FormikDateInput
                                                            name={'to_date'}
                                                            placeholderText={'To date'}
                                                            className={''}
                                                            autoComplete={'off'}
                                                            onChange={handleChange}
                                                            value={values.to_date}
                                                        />
                                                    </div>

                                                    <div className="filters-button">
                                                        <button type='reset' onClick={() => resetFilterForm()}
                                                                className="filters-button-reset"
                                                                disabled={isBtnDisabled}>
                                                            Reset
                                                        </button>
                                                        <button type="submit" className="filters-button-apply">
                                                            Apply Filters
                                                        </button>
                                                    </div>
                                                    <button
                                                        type={'button'}
                                                        className="filters-button-reset"
                                                        style={{width: "100%"}}
                                                        onClick={handleRequestEventModal}
                                                    >
                                                        Can't find your event?
                                                    </button>
                                                </Form>
                                            }
                                        </Formik>
                                    </div>
                                    <div className="matches-list"
                                         onScroll={handleScroll.handleChange}>
                                        <GetEvents/>
                                    </div>
                                </div>
                            </>
                            : <></>
                    }
                </div>
            </div>

            <div className="add-inventory">
                <h2 className="add-inventory-title">Add inventory</h2>
                {selectedEvents.length > 0 && (
                    <div className={'events-container'}>
                        {selectedEvents.map((event) => (
                            <div className={'events-container__item'} key={event.id}>
                                <p>{event.name}, {dateToLocaleString(event.occurs_at)}</p>
                                <div onClick={() => removeEvent(event.id)}>
                                    <AppButton text={'Remove'} color={'red'}></AppButton>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {windowWidth <= MOBILE_WIDTH && <FindMatches chooseCardEvent={toggleMatch} windowWidth={windowWidth}
                                                             selectedEventIds={selectedEventIds}
                                                             currentCardId={currentCardId}/>}
                {
                    selectedEvents.length === 0
                        ?
                        <> </>
                        : <>
                            {windowWidth <= MOBILE_WIDTH ?
                                <>
                                    <h3 className="add-inventory-event-title">City : {event.event_city}</h3>
                                    <h3 className="add-inventory-event-title">Venue : {event.event_venue}</h3>
                                </>
                                :
                                null
                            }
                            <Formik
                                enableReinitialize={true}
                                initialValues={
                                    {
                                        ...ticketInitialFormData,
                                        description: event.event || "",
                                        eventId: selectedEventIds || 0,
                                        currentUser: loggedUser?.id || 0,
                                        seat_type: seatType,
                                        in_hand_date: inHandDay(event),
                                        due_date: currentDate,
                                        section: ''
                                    }
                                }
                                validationSchema={TicketFormValidationSchema}
                                onSubmit={async (values, {setErrors, resetForm}) => {
                                    await handleSubmit(values, setErrors, resetForm);
                                }}
                            >
                                {({isSubmitting, errors, values, touched}) =>
                                    <Form className="add-inventory-form">
                                        <div className={'add-inventory-form-main-inputs'}>
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">Seat Type</label>
                                                <Field name={'seat_type'} as="select"
                                                       style={{display: 'block', backgroundColor: 'white'}}
                                                       className={'add-inventory-form-input'}
                                                       onChange={((event: any) => setSeatType(event.target.value))}
                                                >
                                                    <option value="CONSECUTIVE">Consecutive</option>
                                                    <option value="ALTERNATING">Odd/Even</option>
                                                    <option value="GA">GA</option>
                                                    <option value="PIGGYBACK">Piggyback</option>
                                                </Field>
                                            </div>
                                            {windowWidth > MOBILE_WIDTH ?
                                                <>
                                                    <div>
                                                        <label htmlFor=""
                                                               className="add-inventory-form-label">Quantity*</label>
                                                        <DefaultFormikInput name={'quantity'}
                                                                            type={'number'}
                                                                            placeholder={'Quantity'}
                                                                            autoComplete={'off'}
                                                                            class={'add-inventory-form-input'}
                                                                            onInput={handleQuantityChange}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="" className="add-inventory-form-label">Shown
                                                            Quantity</label>
                                                        <DefaultFormikInput name={'shown_quantity'}
                                                                            placeholder={'Shown Quantity'}
                                                                            autoComplete={'on'}
                                                                            class={'add-inventory-form-input'}
                                                                            type={'number'}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className={'form-container_double'}>
                                                        <div>
                                                            <label htmlFor=""
                                                                   className="add-inventory-form-label">Quantity*</label>
                                                            <DefaultFormikInput name={'quantity'}
                                                                                type={'number'}
                                                                                placeholder={'Quantity'}
                                                                                autoComplete={'off'}
                                                                                class={'form-container_double-input'}
                                                                                onInput={(e: FormEvent<HTMLInputElement>) => {
                                                                                    handleQuantityChange(e);
                                                                                    availabilityOfUpload(setIsElectronicTicket);
                                                                                }}
                                                            ></DefaultFormikInput>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="" className="add-inventory-form-label">Shown
                                                                Quantity</label>
                                                            <DefaultFormikInput name={'shown_quantity'}
                                                                                placeholder={'Shown Quantity'}
                                                                                autoComplete={'on'}
                                                                                class={'form-container_double-input'}
                                                            ></DefaultFormikInput>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {windowWidth > MOBILE_WIDTH ?
                                                <>
                                                    {!euroCategoriesShowStatus ?
                                                        <div>
                                                            <label htmlFor=""
                                                                   className={"add-inventory-form-label"}>Section*</label>
                                                            <DefaultFormikInput name={'section'}
                                                                                placeholder={'Section'}
                                                                                autoComplete={'on'}
                                                                                class={'add-inventory-form-input'}
                                                                                onInput={() => availabilityOfUpload(setIsElectronicTicket)}
                                                            ></DefaultFormikInput>
                                                        </div>
                                                        :
                                                        <>
                                                            {renderEuroCategorySelect(values.section, touched.section)}
                                                        </>
                                                    }
                                                    <div>
                                                        <label htmlFor=""
                                                               className={"add-inventory-form-label"}>Row*</label>
                                                        <DefaultFormikInput name={'row'}
                                                                            placeholder={'Row'}
                                                                            autoComplete={'on'}
                                                                            class={'add-inventory-form-input'}
                                                                            onInput={() => availabilityOfUpload(setIsElectronicTicket)}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {!euroCategoriesShowStatus ?
                                                        <div className={'form-container_double'}>
                                                            <div>
                                                                <label htmlFor=""
                                                                       className={"add-inventory-form-label"}>Section*</label>
                                                                <DefaultFormikInput name={'section'}
                                                                                    placeholder={'Section'}
                                                                                    autoComplete={'on'}
                                                                                    class={'form-container_double-input'}
                                                                                    onInput={() => availabilityOfUpload(setIsElectronicTicket)}
                                                                ></DefaultFormikInput>
                                                            </div>
                                                            <div>
                                                                <label htmlFor=""
                                                                       className={"add-inventory-form-label"}>Row*</label>
                                                                <DefaultFormikInput name={'row'}
                                                                                    placeholder={'Row'}
                                                                                    autoComplete={'on'}
                                                                                    class={'form-container_double-input'}
                                                                                    onInput={() => availabilityOfUpload(setIsElectronicTicket)}
                                                                ></DefaultFormikInput>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            {renderEuroCategorySelect(values.section, touched.section)}
                                                            <div className={mobileClass.container}>
                                                                <label htmlFor=""
                                                                       className={"add-inventory-form-label"}>Row*</label>
                                                                <DefaultFormikInput name={'row'}
                                                                                    placeholder={'Row'}
                                                                                    autoComplete={'on'}
                                                                                    class={'add-inventory-form-input'}
                                                                                    onInput={() => availabilityOfUpload(setIsElectronicTicket)}
                                                                ></DefaultFormikInput>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            }
                                            {seatType === 'PIGGYBACK' && (
                                                <div className={mobileClass.container}>
                                                    <label htmlFor="" className="add-inventory-form-label">2nd Row*</label>
                                                    <DefaultFormikInput
                                                        name={'second-row'}
                                                        placeholder={'2nd Row'}
                                                        autoComplete="on"
                                                        class="add-inventory-form-input"
                                                    />
                                                </div>
                                            )}
                                            {windowWidth > MOBILE_WIDTH ?
                                                <>
                                                    <div className="position-relative">
                                                        <label htmlFor="" className="add-inventory-form-label">
                                                            Low Seat*
                                                            <span className="add-inventory-form-hint">
                                                            ?
                                                            <span className="add-inventory-form-tooltip">
                                                                Pay attention, please! A unique low seat must be specified in this field.
                                                            </span>
                                                        </span>
                                                        </label>
                                                        <DefaultFormikInput name={'low_seat'}
                                                                            placeholder={'Low Seat'}
                                                                            autoComplete={'on'}
                                                                            class={'add-inventory-form-input'}
                                                                            onFocus={() => onSeatFocus()}
                                                                            type={'number'}
                                                                            onInput={() => availabilityOfUpload(setIsElectronicTicket)}
                                                        ></DefaultFormikInput>
                                                        <div
                                                            className={isComponentVisible ? "seats-tooltip visible" : "seats-tooltip invisible"}
                                                            ref={ref}
                                                        >
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="" className="add-inventory-form-label">
                                                            Unit price*
                                                        </label>
                                                        <DefaultFormikInput name={'unit_cost'}
                                                                            placeholder={'Unit price'}
                                                                            autoComplete={'on'}
                                                                            type={'number'}
                                                                            class={'add-inventory-form-input'}
                                                                            onInput={(v: any) => ticketFormData.unit_cost = v.target.value}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                </>
                                                : <>
                                                    <div className={'form-container_double'}>
                                                        <div className={'form-container position-relative'}>
                                                            <label htmlFor="" className="add-inventory-form-label">
                                                                Low Seat*
                                                                <span className="add-inventory-form-hint">
                                                                ?
                                                                <span className="add-inventory-form-tooltip">
                                                                    Pay attention, please! A unique low seat must be specified in this field.
                                                                </span>
                                                            </span>
                                                            </label>
                                                            <DefaultFormikInput name={'low_seat'}
                                                                                placeholder={'Low Seat'}
                                                                                autoComplete={'on'}
                                                                                class={'form-container_double-input'}
                                                                                onFocus={onSeatFocus}
                                                            ></DefaultFormikInput>
                                                            <div
                                                                className={isComponentVisible ? "seats-tooltip visible" : "seats-tooltip invisible"}
                                                                ref={ref}
                                                            >
                                                            </div>
                                                        </div>
                                                        <div className={'form-container'}>
                                                            <label htmlFor="" className="add-inventory-form-label">Unit
                                                                price*</label>
                                                            <DefaultFormikInput name={'unit_cost'}
                                                                                placeholder={'Unit price'}
                                                                                autoComplete={'on'}
                                                                                class={'form-container_double-input'}
                                                            ></DefaultFormikInput>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">Face value*</label>
                                                <DefaultFormikInput name={'face_value'}
                                                                    type={'number'}
                                                                    placeholder={'Face value'}
                                                                    autoComplete={'on'}
                                                                    class={'add-inventory-form-input'}
                                                                    onInput={handleFaceValueChange}
                                                ></DefaultFormikInput>
                                            </div>
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">Currency</label>
                                                <Field name={'currency'} as="select"
                                                       style={{display: 'block', backgroundColor: 'white'}}
                                                       className={'add-inventory-form-input'}>
                                                    {currenciesArray.map(currency => (
                                                        <option key={currency} value={currency}>
                                                            {currency}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">
                                                    {
                                                        euroEventIds.filter(event => selectedEventIds.includes(event)).length > 0
                                                            ? 'VGG Listing ID'
                                                            : 'Internal notes'
                                                    }
                                                </label>
                                                <DefaultFormikInput name={'internal_notes'}
                                                                    placeholder={
                                                                        euroEventIds.filter(event => selectedEventIds.includes(event)).length > 0
                                                                            ? 'VGG Listing ID'
                                                                            : 'Internal notes'
                                                                    }
                                                                    autocomplete={'off'}
                                                                    class={'add-inventory-form-input'}
                                                                    validate={validateNotes}
                                                ></DefaultFormikInput>
                                            </div>
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">Public notes</label>
                                                <DefaultFormikInput name={'public_notes'}
                                                                    placeholder={'Public notes'}
                                                                    autocomplete={'off'}
                                                                    class={'add-inventory-form-input'}
                                                ></DefaultFormikInput>
                                            </div>
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">Tags</label>
                                                <div className={'default-formik-input'}>
                                                    <TagPicker
                                                        className={'tag-picker'}
                                                        creatable={true}
                                                        onChange={(selected) => {
                                                            const nonEmptyTags = selected.filter((tag: string) => tag.trim() !== '')
                                                            setSelectedTags(nonEmptyTags)
                                                        }}
                                                        data={vendorInventoryTagsData}
                                                        placeholder="Select tags or create a new one"
                                                        block
                                                        value={selectedTags}
                                                        renderMenuItem={(label, item) => {
                                                            return <>{label}</>;
                                                        }}
                                                        renderValue={(values, items, tags) => {
                                                            return values.map((tag: any) => (
                                                                <Tag key={tag}>
                                                                    {tag}
                                                                </Tag>
                                                            ));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">Stock Type</label>
                                                <Field name={'stock_type'} as="select"
                                                       style={{display: 'block', backgroundColor: 'white'}}
                                                       className={
                                                           !values.stock_type && touched.stock_type
                                                               ? 'select-error add-inventory-form-input'
                                                               : 'add-inventory-form-input'
                                                       }
                                                       onClick={() => availabilityOfUpload(setIsElectronicTicket)}
                                                       onInput={setStockType}
                                                >
                                                    <option value="" disabled={true}>Select Type</option>
                                                    <option value="HARD">Hard</option>
                                                    <option value="ELECTRONIC">E-ticket</option>
                                                    <option value="FLASH">Flash</option>
                                                    <option value="MOBILE_SCREENCAP">Mobile QR</option>
                                                    <option value="MOBILE_TRANSFER">Mobile Transfer</option>
                                                    <option value="PAPERLESS">Paperless Walk-In</option>
                                                    <option value="PAPERLESS_CARD">Paperless Gift Card</option>
                                                </Field>
                                            </div>
                                            <div
                                                className={`${windowWidth > MOBILE_WIDTH ? 'split-type' : 'form-container'}`}>
                                                <label htmlFor="" className="add-inventory-form-label">
                                                    Split Type
                                                    <span className="add-inventory-form-hint">
                                                            ?
                                                            <span
                                                                className="add-inventory-form-tooltip split-type-hint-width">
                                                            The Default split type will sell even quantities from listings with even quantities and will always avoid leaving a single item if the listing has an odd quantity. For instance, if you have a 10-pack set to Default, customers can purchase 2, 4, 6, 8, or all 10 tickets. Similarly, with an 11-pack, customers can buy any quantity except for 10.
                                                            </span>
                                                        </span>
                                                </label>
                                                <Field name={'split_type'} as="select"
                                                       onClick={handleSplitTypeChange}
                                                       style={{display: 'block', backgroundColor: 'white'}}
                                                       className={'add-inventory-form-input'}>
                                                    {SPLIT_TYPES_LABEL.map((type) => (
                                                        <option value={type.key} key={type.key}>{type.label}</option>
                                                    ))}
                                                </Field>
                                                <div className={"split-type__custom"}></div>
                                            </div>
                                            <div className={mobileClass.container}>
                                                <label htmlFor="" className="add-inventory-form-label">In hand date</label>
                                                <div>
                                                    <FormikDateInput
                                                        name={'in_hand_date'}
                                                        class={'add-inventory-form-input'}
                                                        sendDate={(date: any) => setInHandDate(date)}
                                                    />
                                                </div>
                                            </div>

                                            <div className={mobileClass.container} style={{width: "100%"}}>
                                                <label htmlFor="" className="add-inventory-form-label">Disclosures</label>
                                                <div className={'default-formik-input'}>
                                                    <TagPicker
                                                        className={'disclosure-picker'}
                                                        creatable={false}
                                                        onChange={(selected) => {
                                                            const nonEmptyDisclosures = selected.filter((disclosure: string) => disclosure.trim() !== '')
                                                            setSelectedDisclosures(nonEmptyDisclosures)
                                                        }}
                                                        data={vendorInventoryDisclosuresData}
                                                        placeholder="Select disclosures"
                                                        block
                                                        placement={'autoVerticalStart'}
                                                        value={selectedDisclosures}
                                                        renderMenuItem={(label, item) => {
                                                            return <>{label}</>;
                                                        }}
                                                        renderValue={(values, items, tags) => {
                                                            return values.map((disclosure: any) => (
                                                                <Tag key={disclosure}>
                                                                    {disclosure}
                                                                </Tag>
                                                            ));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className={mobileClass.container}>
                                                    <label htmlFor=""
                                                           className="add-inventory-form-label">PDF/Barcode</label>
                                                    <button
                                                        className="filters-button-reset"
                                                        type="button"
                                                        disabled={isElectronicTicket}
                                                        onClick={() => setShowUploadPdf(true)}
                                                    >
                                                        Upload
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'checkbox-field'}>
                                                <label className="add-inventory-form-label">Broadcast</label>
                                                <Field name={'broadcast'} type={'checkbox'}/>
                                            </div>
                                            {
                                                showTicomboForm
                                                || (loggedUser!.role_id === RolesEnum.Admin || loggedUser!.role_id === RolesEnum.SuperAdmin)
                                                || (event.stubhub_event && event.stubhub_event.stubhub_sections?.length && loggedUser!.api_keys?.stubhub)
                                                    ?
                                                    <div className={'w-100 mt-4'}>
                                                        <h3 className={'mb-4'}>Push to Platform</h3>
                                                        {
                                                            showTicomboForm &&
                                                            <TicomboInventoryForm ticomboEvent={event!.ticombo_event!}
                                                                                  sbTicketData={ticketFormData}
                                                                                  ticomboFormData={ticomboFormData}
                                                                                  dispatch={setTicomboDataParam}
                                                                                  key={JSON.stringify(ticomboFormData) + JSON.stringify(ticketFormData)}
                                                            />
                                                        }
                                                        {
                                                            (loggedUser!.role_id === RolesEnum.Admin || loggedUser!.role_id === RolesEnum.SuperAdmin) &&
                                                            <LTGDeliveryForm sbEventId={event.id}
                                                                             isSubmitting={isSubmitting}
                                                                             alias={values.section}/>
                                                        }
                                                        {
                                                            (event.stubhub_event && event.stubhub_event.stubhub_sections?.length && loggedUser!.api_keys?.stubhub) &&
                                                            <StubhubParams stubhub_event={event.stubhub_event}
                                                                           mobileClass={mobileClass}/>
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className={'po-data-container'}>
                                            <div className={mobileClass.container}>
                                                <h5>Purchase order data</h5>
                                                <div className={'po-data-inputs add-inventory-form-main-inputs'}>

                                                    <div className={'po-data-inputs_single-input'}>
                                                        <label htmlFor="" className="add-inventory-form-label">Due
                                                            date</label>
                                                        <AppDatePicker customClass={'add-inventory-form-input'}
                                                                       name={'due_date'} placeholder={'Due date'}/>
                                                    </div>
                                                    <div className={'po-data-inputs_single-input'}>
                                                        <label htmlFor="" className="add-inventory-form-label">Public
                                                            notes</label>
                                                        <DefaultFormikInput name={'po_public_notes'}
                                                                            placeholder={'Public notes'}
                                                                            autocomplete={'off'}
                                                                            class={'add-inventory-form-input'}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                    <div className={'po-data-inputs_single-input'}>
                                                        <label htmlFor="" className="add-inventory-form-label">Internal
                                                            notes</label>
                                                        <DefaultFormikInput name={'po_internal_notes'}
                                                                            placeholder={'Internal notes'}
                                                                            autocomplete={'off'}
                                                                            class={'add-inventory-form-input'}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                    <div className={'po-data-inputs_single-input'}>
                                                        <label htmlFor="" className="add-inventory-form-label">External
                                                            reference</label>
                                                        <DefaultFormikInput name={'po_external_reference'}
                                                                            placeholder={'External reference'}
                                                                            autocomplete={'off'}
                                                                            class={'add-inventory-form-input'}
                                                        ></DefaultFormikInput>
                                                    </div>
                                                    <div className={'po-data-inputs_single-input'}>
                                                        <label htmlFor=""
                                                               className="add-inventory-form-label">Currency</label>
                                                        <Field name={'po_currency'} as="select"
                                                               style={{display: 'block', backgroundColor: 'white'}}
                                                               className={'add-inventory-form-input'}>
                                                            {currenciesArray.map(currency => (
                                                                <option key={currency} value={currency}>
                                                                    {currency}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </div>
                                                    <div className={'po-data-inputs_single-input'}>
                                                        <label htmlFor=""
                                                               className="add-inventory-form-label">Vendor*</label>
                                                        <div className={'vendor-input-container'}>
                                                            <AppSmartSearch
                                                                inputId={'user_vendor_name'}
                                                                showErrorOnFocus={false}
                                                                name={'user_vendor_name'}
                                                                placeholder={'Vendor'}
                                                                customClass={'add-inventory-form-input'}
                                                                requestFunction={userVendorSearchByDisplayName}
                                                                valuesKey={'display_name'}
                                                            />
                                                            <button className="hold__form_customer-button" type="button"
                                                                    onClick={() => handleVendorModal()}>Create
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="add-inventory-form-submit-btn" type="submit"
                                                disabled={isSubmitting}>Submit And Assign Purchase Order
                                        </button>
                                    </Form>
                                }
                            </Formik>
                            <AppModal
                                title={'Ticket Uploader'}
                                form={<UploadPdfForm
                                    quantity={(document.getElementsByName('quantity')[0] as HTMLInputElement)?.value}
                                    lowSeat={(document.getElementsByName('low_seat')[0] as HTMLInputElement)?.value}
                                    section={(document.getElementsByName('section')[0] as HTMLInputElement)?.value}
                                    row={(document.getElementsByName('row')[0] as HTMLInputElement)?.value}
                                    event={event}
                                    barcodes={barcodes}
                                    setBarcodes={setBarcodes}
                                    filesLinks={filesLinks}
                                    setFilesLinks={setFilesLinks}
                                    showHandle={() => setShowUploadPdf(false)}
                                />}
                                show={showUploadPdf}
                                showHandle={() => setShowUploadPdf(false)}
                                onClose={() => {
                                }}
                            />
                        </>
                }
                <AppModal
                    centered={true}
                    title={'Request an event'}
                    form={
                        <RequestEventForm close={handleRequestEventModal}/>
                    }
                    show={requestModalOpen}
                    showHandle={handleRequestEventModal}
                    onClose={() => handleRequestEventModal()}
                />
                <AppModal
                    headerClassName={'customers-modal-header'}
                    headerTitleClassName={'customers-modal-header__title'}
                    size={'lg'}
                    backdrop={'static'}
                    keyboard={false}
                    title={'Create new vendor'}
                    form={
                        <CustomerOrVendorForm
                            customerFormMode={false}
                            onClose={() => handleVendorModal()}
                            tags={tags}
                            selectedTags={selectedTags}
                            setSelectedTags={setSelectedTags}
                            vendor={undefined}
                            editMode={false}
                        />
                    }
                    show={isModalOpen}
                    showHandle={handleVendorModal}
                    onClose={() => handleVendorModal()}
                />
            </div>
        </>
    )
}

export default SellTicketPage;
