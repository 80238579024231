import "./AppTable.scss";

import React, {FC, useEffect} from "react";
import Table from "react-bootstrap/Table";
import {heightTableStyle} from "../../../helpers/SpecificStyles";
import AppLoader from "../../Loader/AppLoader/AppLoader";
import AppPagination from "../../Pagination/AppPagination/AppPagination";

interface Props {
    columns: string[],
    pagination?: {
        lastPage: number,
        currentPage: number,
        total_items: number
    },
    onPageChange?: (page: number, perPage: number) => void,
    filterValues?: any,
    children: JSX.Element,
    isLoading?: boolean,
    withAutoHeight?: boolean
    customTableWrapperClass?: string
}

const AppTable: FC<Props> = (
    {
        columns,
        pagination,
        onPageChange,
        children,
        isLoading,
        withAutoHeight = true,
        customTableWrapperClass = ''
    }
) => {

    useEffect(() => {
        if (withAutoHeight) {
            const element = document.getElementsByClassName('app-paginated-table-wrapper')[0];

            (element as HTMLElement).style.height = heightTableStyle('app-paginated-table').height;
        }
    }, []);

    return (
        <div className="app-paginated-table-wrapper">
            <div
                className={`app-paginated-table` + ' ' + customTableWrapperClass}>
                {
                    isLoading ?
                        <AppLoader/>
                        :
                        <Table>
                            <thead className={"stick-header-events-table"}>
                            <tr>
                                {
                                    columns.map((key, index) => (
                                        <th key={index}>{key}</th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {children}
                            </tbody>
                        </Table>
                }
            </div>
            {pagination && <AppPagination pagination={pagination} onPageChange={onPageChange}/>}
        </div>
    );
}

export default AppTable;
