import React, {FC, useState} from "react";
import "./AppSelect.scss";
import SelectArrow from '../../../assets/images/other/select-arrow.svg';
import {Field, useFormikContext} from "formik";

interface Props {
    items: { name: string | number | JSX.Element, value: string | number }[],
    value: string | number,
    name: string,
    placeholder?: string,
    customLiClass?: string
}

const AppSelect: FC<Props> = ({items, value, name, placeholder, customLiClass = ''}) => {
    const {setFieldValue} = useFormikContext();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleSelect = (value: string | number) => {
        setFieldValue(name, value);
    };

    return (
        <div className="app-select" onClick={() => setIsOpen(!isOpen)}>
            <div className={"app-select__header"}>
                <div className="app-select__header-name">
                    {value
                        ? items.find((item) => item.value === value)?.name
                        : <p className="app-select__body-placeholder">{placeholder}</p>
                    }
                </div>
                <span className={isOpen ? "app-select__header-arrow-up" : "app-select__header-arrow"}>
                    <img src={SelectArrow} alt="arrow"/>
                </span>
            </div>
            <ul className={isOpen ? "" : "hidden"}>
                {items.map((item, index) => (
                    <li key={index}
                        className={' ' + customLiClass}
                        data-value={item.value}
                        onClick={() => handleSelect(item.value)}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
            <Field name={name} className="d-none" defaultValue={value}/>
        </div>
    )
}

export default AppSelect;
