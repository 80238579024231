import "./AppLoader.scss";
import React, {FC} from "react";

interface Props {
    isLoading?: boolean
}

const AppLoader: FC<Props> = ({isLoading}) => {
    return (
        <div className="app-loader-wrapper">
            <span className="app-loader"></span>
        </div>
    );
}

export default AppLoader;
