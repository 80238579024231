import React, { useState } from 'react';
import { Inventory } from '../../../../../../models/Inventory';
import './EventIntegrations.scss';
import { Divider, Message, Popover, useToaster, Whisper, WhisperInstance } from 'rsuite';
import { EventIntegration } from './EventIntegration';
import { IntegrationsEnum } from '../../../../../../enums/IntegrationsEnum';
import { integrations } from '../../../../../../constants/Integrations';
import { getInventoryCustomPriceRequest } from '../../../../../../@api/User';
import { EventIntegrationCustomPrice } from '../../../../../../models/EventIntegrationCustomPrice';
import { HttpSuccessResponse } from '../../../../../../@api/Responses/HttpSuccessResponse';
import DefaultSpinner from '../../../../../../ui/Spinners/DefaultSpinner/DefaultSpinner';
import { InventoryCreatorTypeEnum } from '../../../../../../enums/InventoryCreatorTypeEnum';
import { ERROR_DURATION } from '../../../../../../constants/Constants';

type Props = {
    inventory: Inventory;
}
export const EventIntegrations: React.FC<Props> = ({inventory}) => {
    const toaster = useToaster();
    const triggerRef = React.useRef<WhisperInstance>(null);

    const enabledIntegrations: IntegrationsEnum[] = [];

    const [integrationCustomPrices, setIntegrationCustomPrices]
        = useState<EventIntegrationCustomPrice[] | null>(null);

    const ticomboInventory = inventory?.ticombo_inventory;
    if (ticomboInventory?.should_push && ticomboInventory?.created_by === InventoryCreatorTypeEnum.BROKER) {
        enabledIntegrations.push(IntegrationsEnum.Ticombo);
    }
    if (inventory.ltg_sb_mapping) {
        // disable LTG until fully implemented
        // enabledIntegrations.push(IntegrationsEnum.Ltg);
    }

    if (!inventory.id) {
        return null;
    }

    if (!enabledIntegrations.length) {
        return (<>None</>);
    }

    const showError = (message: React.ReactNode) => {
        toaster.push(<Message showIcon closable type={'error'}>{message}</Message>, {duration: ERROR_DURATION});
        triggerRef?.current?.close();
    }

    const open = () => {
        if (enabledIntegrations.length === 0) {
            return;
        }

        getInventoryCustomPriceRequest(inventory.id).then(res => {
            if (res instanceof HttpSuccessResponse) {
                const supportedCustomPrices = res.data.filter(i => enabledIntegrations.includes(i.integration_type));
                setIntegrationCustomPrices(
                    supportedCustomPrices
                );
                if (supportedCustomPrices.length > 0) {
                    triggerRef?.current?.open();
                } else {
                    showError('Oops, something went wrong. Please try again and if the issue persists - contact support');
                }
            } else {
                showError(res.message);
            }
        }).catch(e => {
            showError(e.message);
        })
    }

    const speaker = (
        <Popover className={'inventory-integrations-popover'}>
            {integrationCustomPrices ? (
                integrationCustomPrices.map(integration=> (
                    <EventIntegration
                        key={`${inventory.id}-integration-form-${integration.integration_type}`}
                        inventory={inventory}
                        currency={integration.currency || 'USD'}
                        customPrice={integration.custom_price}
                        integrationPrice={integration.integration_price}
                        integrationType={integration.integration_type}
                    ></EventIntegration>
                ))
            ) : (
                <DefaultSpinner size={36} thickness={2} />
            )}
        </Popover>
    );

    return (
        <Whisper placement="auto" trigger="click" controlId="inventory-integrations-click" speaker={speaker} ref={triggerRef} >
            <span className={'inventory-integrations'} onClick={open}>
                {enabledIntegrations.map((integrationType, index) => {
                    const shortLabel = integrations.find(i => i.key === integrationType)?.shortName;
                    return (
                        <React.Fragment key={`${inventory.id}-integration-${integrationType}`}>
                            {index > 0 && <Divider vertical />}
                            <span className={'option text-primary'}>{shortLabel || integrationType}</span>
                        </React.Fragment>
                    )}
                )}
            </span>
        </Whisper>
    );
};
