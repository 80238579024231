import './AppButton.scss';
import {CSSProperties, FC} from "react";
import DefaultSpinner from "../../Spinners/DefaultSpinner/DefaultSpinner";

interface Props {
    text: string,
    type?: "button" | "submit" | "reset",
    size?: "sm" | "md" | "lg",
    style?: CSSProperties,
    color?: "red" | "gray",
    disabled?: boolean
    isLoading?: boolean
}

const AppButton: FC<Props> = ({
    text,
    type,
    style,
    size = 'md',
    color = 'red',
    disabled,
    isLoading
}) => {

    let spinnerSize = 24;
    let thickness = 5;

    if (size === 'sm') {
        spinnerSize = 18;
        thickness = 2;
    }

    return (
        <button
            className={
                "app-button" +
                " app-button-" + color +
                " app-button-" + size
            }
            style={style}
            type={type ?? 'button'}
            disabled={disabled ?? false}
        >
            {isLoading && <DefaultSpinner size={spinnerSize} thickness={thickness} /> }
            {!isLoading && text}
        </button>
    );
}

export default AppButton;
