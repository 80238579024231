import React, {FC, useState} from "react";
import {Form, Formik} from "formik";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import DefaultFormikInput from "../../../../../../components/Inputs/DefaultFormikInput/DefaultFormikInput";
import {BalanceValidationSchema} from "../../../Data";
import './PaymentForm.scss';
import {stripeReplenish} from "../../../../../../@api/Stripe";
import {paypalReplenish} from "../../../../../../@api/Paypal";
import {calculateAmountWithStripeFee} from "../../../../../../helpers/CalculateAmountWithStripeFee";

interface PaymentFormProps {
    onSubmit: React.Dispatch<void>;
}

const PaymentForm: FC<PaymentFormProps> = ({onSubmit}) => {
    const elements = useElements();
    const stripe = useStripe();

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isPaypal, setIsPaypal] = useState(true);
    const [inputAmount, setInputAmount] = useState<number>(0);

    const cardElementOptions = {
        hidePostalCode: true,
    };

    const formContent = () => {
        return (
            <>
                <div className={'input-container'}>
                    <p className={'input-container_text'}>Enter Amount</p>
                    <DefaultFormikInput
                        disabled={isDisabled}
                        type={"number"}
                        name={'amount'}
                        placeholder={'Enter amount'}
                        class={'stripe__input'}
                        autoComplete={'off'}
                        onInput={(e: any)=> setInputAmount(e.target.value)}
                    >
                    </DefaultFormikInput>

                </div>
                {!isPaypal &&
                <div>
                    <p>Please note the service charges a transaction fee of 2.9% + .0.30 cents of your deposit amount</p>
                    <p>Total amount: {calculateAmountWithStripeFee(inputAmount)}</p>
                </div>
                }

                {isLoading ?
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                    :
                    <button disabled={isDisabled} className={'payment'} type={"submit"}>Payment</button>
                }
                {message && <div id="payment-message">{message}</div>}
            </>
        )
    };

    const handleFormSubmit = async (values: { amount: number }) => {
        setIsDisabled(true);
        setIsLoading(true);
        setInputAmount(values.amount);

        if (isPaypal) {
            await updateBalanceByPayPal(values.amount);
        } else {
            await updateUserBalance(values.amount);
        }

        setIsDisabled(false);
        setIsLoading(false);
    };

    const updateUserBalance = async (amount: number) => {
        try {
            const cardElement = elements?.getElement(CardElement);

            if (!stripe || !elements) {
                return;
            }

            const {data} = await stripeReplenish(amount, 'usd');
            const {error} = await stripe.confirmCardPayment(data.clientSecret, {
                payment_method: {
                    // @ts-ignore
                    card: cardElement
                },
            });

            if (error?.type === "card_error" || error?.type === "validation_error") {
                setMessage(error.message);
            } else {
                onSubmit();
                alert('Thank you for your payment! We are currently processing your transaction. Please wait for a moment...');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateBalanceByPayPal = async (amount: number) => {
        try {
            const res = await paypalReplenish(amount);
            window.location.replace(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div>
                <p>Choose Payment Method</p>
                <select
                    className={'payment__methods'}
                    onChange={(event) => setIsPaypal(event.target.value === 'PayPal')}
                >
                    <option>PayPal</option>
                    <option>Credit card</option>
                </select>
            </div>

            <Formik
                initialValues={{amount: 0}}
                validationSchema={BalanceValidationSchema}
                onSubmit={handleFormSubmit}
            >
                <Form className={'stripe'}>
                    {!isPaypal && <CardElement options={cardElementOptions}/>}
                    {formContent()}
                </Form>
            </Formik>
        </>
    );
};

export default PaymentForm;
